<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col h-full" :class="wrapClass">
      <visits-header
        :header-title="pageTitle"
        :segments-id="segmentsId"
        @on-toggle-filter="onToggleFilters"
        @on-toggle-delete-filter="onToggleDeleteFilters"
        @on-toggle-edit-filter="onToggleFilters"
      />
      <visits-table
        :contacts="records"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        :on-click-contact="openContactInfoPanel"
        :active-contact-id="selectedContactId"
        @on-sort-change="onSortChange"
      />
      <table-footer
        class="bg-white dark:bg-slate-800"
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="15"
        @page-change="onPageChange"
      />
    </div>
    <visit-info-panel
      v-if="showContactViewPane"
      :inbox-id="inboxId"
      :contact="selectedContact"
      :on-close="closeContactInfoPanel"
    />
    <woot-modal
      :show.sync="showFiltersModal"
      :on-close="closeAdvanceFiltersModal"
      size="medium"
    >
      <contacts-advanced-filters
        v-if="showFiltersModal"
        :on-close="closeAdvanceFiltersModal"
        :initial-filter-types="contactFilterItems"
        :initial-applied-filters="appliedFilter"
        :active-segment-name="activeSegmentName"
        :is-segments-view="hasActiveSegments"
        :set-contact-filters-action="'visits/setVisitFilters'"
        @applyFilter="onApplyFilter"
        @updateSegment="onUpdateSegment"
        @clearFilters="clearFilters"
      />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VisitsHeader from './VisitsHeader';
import VisitsTable from './components/VisitsTable';
import VisitInfoPanel from './components/VisitInfoPanel';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import ContactsAdvancedFilters from '../contacts/components/ContactsAdvancedFilters';
import contactFilterItems from './contactFilterItems';
import filterQueryGenerator from '../../../helper/filterQueryGenerator';

const DEFAULT_PAGE = 1;

export default {
  components: {
    VisitsHeader,
    VisitsTable,
    VisitInfoPanel,
    TableFooter,
    ContactsAdvancedFilters,
  },
  props: {
    inboxId: {
      type: [String, Number],
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      appliedFilter: [],
      contactFilterItems: contactFilterItems.map(filter => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
      sortConfig: { created_at: 'desc' },
      segmentsQuery: {},
      selectedContactId: '',
      showFiltersModal: false,
      showDeleteSegmentsModal: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'visits/getVisits',
      uiFlags: 'visits/getUIFlags',
      meta: 'visits/getMeta',
      segments: 'customViews/getCustomViews',
      getAppliedContactFilters: 'visits/getAppliedVisitFilters',
    }),
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.activeSegment && this.segmentsId !== 0;
    },
    pageTitle() {
      if (this.hasActiveSegments) {
        return this.activeSegment.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('VISITS_PAGE.HEADER');
    },
    selectedContact() {
      if (this.selectedContactId) {
        const contact = this.records.find(
          item => this.selectedContactId === item.id
        );
        return contact;
      }
      return undefined;
    },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber)
        && selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    wrapClass() {
      return this.showContactViewPane ? 'w-[70%]' : 'w-full';
    },
    activeSegment() {
      if (this.segmentsId) {
        const [firstValue] = this.segments.filter(
          view => view.id === Number(this.segmentsId)
        );
        return firstValue;
      }
      return undefined;
    },
    activeSegmentName() {
      return this.activeSegment?.name;
    },
  },
  watch: {
    inboxId() {
      this.fetchContacts(DEFAULT_PAGE, this.inboxId);
      if (this.hasAppliedFilters) {
        this.clearFilters();
      }
    },
  },
  mounted() {
    this.fetchContacts(this.pageParameter);
  },
  methods: {
    onApplyFilter(payload) {
      this.closeContactInfoPanel();
      this.segmentsQuery = filterQueryGenerator(payload);
      this.$store.dispatch('visits/filter', {
        inboxId: this.inboxId,
        queryPayload: filterQueryGenerator(payload),
      });
      this.showFiltersModal = false;
    },
    clearFilters() {
      this.$store.dispatch('visits/clearVisitFilters');
      this.fetchContacts(this.pageParameter);
    },
    onUpdateSegment(payload, segmentName) {
      const payloadData = {
        ...this.activeSegment,
        name: segmentName,
        query: filterQueryGenerator(payload),
      };
      this.$store.dispatch('customViews/update', payloadData);
      this.closeAdvanceFiltersModal();
    },
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { created_at: 'desc' };
        sortAttr = '-created_at';
      }
      return sortAttr;
    },
    fetchContacts(page, inboxId = '') {
      this.updatePageParam(page);
      const requestParams = {
        inboxId,
        sortAttr: this.getSortAttribute(),
        page
      };
      this.$store.dispatch('visits/get', requestParams);
    },
    // fetchSavedFilteredContact(payload, page) {
    //   if (this.hasActiveSegments) {
    //     this.updatePageParam(page);
    //     this.$store.dispatch('visits/filter', {
    //       inboxId: this.inboxId,
    //       queryPayload: filterQueryGenerator(payload),
    //       page,
    //     });
    //   }
    // },
    fetchFilteredContacts(page) {
      if (this.hasAppliedFilters) {
        const payload = this.segmentsQuery;
        this.updatePageParam(page);
        this.$store.dispatch('visits/filter', {
          inboxId: this.inboxId,
          queryPayload: payload,
          sortAttr: this.getSortAttribute(),
          page,
        });
      }
    },
    onSortChange(params) {
      this.selectedContactId = '';
      this.sortConfig = params;
      if (this.hasAppliedFilters) {
        this.fetchFilteredContacts(DEFAULT_PAGE);
      } else {
        this.fetchContacts(DEFAULT_PAGE, this.inboxId);
      }
    },
    onPageChange(page) {
      this.selectedContactId = '';
      // if (this.segmentsId !== 0) {
      //   const payload = this.activeSegment.query;
      //   this.fetchSavedFilteredContact(payload, page);
      // }
      if (this.hasAppliedFilters) {
        this.fetchFilteredContacts(page);
      } else {
        this.fetchContacts(page, this.inboxId);
      }
    },
    openContactInfoPanel(contactId) {
      this.selectedContactId = contactId;
      this.showContactInfoPanelPane = true;
    },
    closeContactInfoPanel() {
      this.selectedContactId = '';
      this.showContactInfoPanelPane = false;
    },
    onToggleFilters() {
      if (this.hasActiveSegments) {
        this.initializeSegmentToFilterModal(this.activeSegment);
      }
      this.showFiltersModal = true;
    },
    closeAdvanceFiltersModal() {
      this.showFiltersModal = false;
      this.appliedFilter = [];
    },
    onToggleDeleteFilters() {
      this.showDeleteSegmentsModal = true;
    },
  },
};
</script>
