<template>
  <div v-if="show" class="fixed inset-0 z-50 flex-1 overflow-auto">
    <modal :show.sync="show" :on-close="cancel">
      <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10">
                  <svg class="h-6 w-6 text-yellow-700" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 id="modal-title" class="text-base font-semibold leading-6 text-slate-900">{{ $t('PAYMENT_MGMT.NEED_PAYMENT.LOCKED') }}</h3>
                  <div class="mt-2">
                    <p class="text-sm text-slate-700">{{ $t('PAYMENT_MGMT.NEED_PAYMENT.DESC') }}</p>
                    <p class="text-sm">
                      <a :href="pricingUrl" target="_blank">{{ $t('PAYMENT_MGMT.NEED_PAYMENT.COMPARE') }} ⭢</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                <button type="button" class="cursor-pointer inline-flex w-full justify-center rounded-md bg-woot-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-woot-500 sm:w-auto" @click="upgrade">{{ $t('PAYMENT_MGMT.NEED_PAYMENT.PAY_NOW') }}</button>
                <button type="button" class="cursor-pointer mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-500 shadow-sm ring-1 ring-inset ring-slate-200 hover:bg-slate-50 sm:ml-3 sm:mt-0 sm:w-auto" @click="onClose">{{ $t('PAYMENT_MGMT.NEED_PAYMENT.CANCEL') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  props: {
  },
  data: () => ({
    show: false,
  }),
  computed: {
    pricingUrl() {
      return window.chatwootConfig.mainURL + '/pricing';
    },
  },
  methods: {
    showDialog() {
      this.show = true;
    },
    upgrade() {
      this.$router.push({
        name: 'payment_list',
        params: { accountId: this.$route.params.accountId },
      });
    },
    onClose() {
      this.show = false;
    },
    cancel() {
      this.show = false;
    },
  }
};
</script>
