<template>
  <div
    v-show="filteredPayments.length"
    class="bg-white dark:bg-slate-800 border border-solid border-slate-75
        dark:border-slate-700/50 rounded-sm mb-4 p-4"
  >
    <p> <b>{{ $t('PAYMENT_MGMT.PAYMENTS.TITLE') }}</b> </p>
    <div class="w-full">
      <table>
        <thead>
          <th v-for="thHeader in $t('PAYMENT_MGMT.PAYMENTS.TABLE_HEADER')" :key="thHeader">
            {{ thHeader }}
          </th>
        </thead>
        <tbody>
          <tr v-for="p in filteredPayments" :key="p.id">
            <td> {{ p.created_at.split('T')[0] }} </td>
            <td> {{ p.paid_at.split('T')[0] }} </td>
            <td> {{ p.currency }} </td>
            <td> {{ p.unit_amount }} </td>
            <td> {{ p.quantity }} </td>
            <td> {{ p.total_amount }} </td>
            <td> {{ $t('PAYMENT_MGMT.PAYMENT_METHODS.' + p.payment_method.toUpperCase()) }} </td>
            <td> {{ paymentPlanName(p.plan_id) }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filteredPayments: {
      type: Array,
      required: true
    },
    paymentPlanName: {
      type: Function,
      default: () => ''
    }
  }
};
</script>
