/* global axios */
import { DataManager } from '../helper/CacheHelper/DataManager';
import ApiClient from './ApiClient';

class CacheEnabledApiClient extends ApiClient {
  constructor(resource, options = {}) {
    super(resource, options);
    this.dataManager = new DataManager(this.accountIdFromRoute);
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    throw new Error('cacheModelName is not defined');
  }

  get(cache = false) {
    if (cache) {
      return this.getFromCache();
    }

    return this.getFromNetwork();
  }

  getFromNetwork() {
    return axios.get(this.url);
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data.payload;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: { payload: dataToParse } };
  }

  async getFromCache() {
    return this.getFromNetwork();
    // FIXME 为了解决脏缓存的问题， 这里先都删掉了， 后续如果有性能问题再考虑优化
    // try {
    //   // IDB is not supported in Firefox private mode: https://bugzilla.mozilla.org/show_bug.cgi?id=781982
    //   await this.dataManager.initDb();
    // } catch {
    //   return this.getFromNetwork();
    // }

    // const { data } = await axios.get(
    //   `/api/v1/accounts/${this.accountIdFromRoute}/cache_keys`
    // );
    // const cacheKeyFromApi = data.cache_keys[this.cacheModelName];
    // const isCacheValid = await this.validateCacheKey(cacheKeyFromApi);

    // let localData = [];
    // if (isCacheValid) {
    //   localData = await this.dataManager.get({
    //     modelName: this.cacheModelName,
    //   });
    // }

    // if (localData.length === 0) {
    //   return this.refetchAndCommit(cacheKeyFromApi);
    // }

    // return this.marshallData(localData);
  }

  async refetchAndCommit(newKey = null) {
    const response = await this.getFromNetwork();

    try {
      await this.dataManager.initDb();

      this.dataManager.replace({
        modelName: this.cacheModelName,
        data: this.extractDataFromResponse(response),
      });

      await this.dataManager.setCacheKeys({
        [this.cacheModelName]: newKey,
      });
    } catch {
      // Ignore error
    }

    return response;
  }

  async validateCacheKey(cacheKeyFromApi) {
    if (!this.dataManager.db) {
      await this.dataManager.initDb();
    }

    const cachekey = await this.dataManager.getCacheKey(this.cacheModelName);
    return cacheKeyFromApi === cachekey;
  }
}

export default CacheEnabledApiClient;
