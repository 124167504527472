import InboxMembersAPI from '../../api/inboxMembers';

export const actions = {
  get(_, { inboxId }) {
    return InboxMembersAPI.show(inboxId);
  },
  create(_, { inboxId, agentList }) {
    return InboxMembersAPI.update({ inboxId, agentList });
  },
  createByAllAgent: async ({ dispatch, rootGetters }, { inboxId }) => {
    await dispatch('agents/get', null, { root: true });
    let agents = rootGetters['agents/getAgents'];
    const agentList = agents.map(agent => agent.id);
    return InboxMembersAPI.update({ inboxId, agentList });
  },
};

export default {
  namespaced: true,
  actions,
};
