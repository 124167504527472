<template>
  <div class="conversation--details">
    <contact-details-item
      v-if="ipAddress"
      :title="$t('CONTACT_PANEL.IP_ADDRESS')"
      :value="ipAddress"
      class="conversation--attribute"
    />
    <contact-details-item
      v-if="browserName"
      :title="$t('CONTACT_PANEL.BROWSER')"
      :value="browserName"
      class="conversation--attribute"
    />
    <contact-details-item
      v-if="platformName"
      :title="$t('CONTACT_PANEL.OS')"
      :value="platformName"
      class="conversation--attribute"
    />
    <contact-details-item
      v-if="deviceName"
      :title="$t('VISIT_PANEL.DEVICE_NAME')"
      :value="deviceName"
      class="conversation--attribute"
    />
  </div>
</template>

<script>
import ContactDetailsItem from '../../conversation/ContactDetailsItem.vue';

export default {
  components: {
    ContactDetailsItem,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    referer() {
      return this.contact.referrer_url;
    },
    browserName() {
      if (!this.contact.browser_name) {
        return '';
      }
      return `${this.contact.browser_name} ${this.contact.browser_version}`;
    },
    platformName() {
      if (!this.contact.platform_name) {
        return '';
      }
      return `${this.contact.platform_name || ''} ${this.contact.platform_version || ''}`;
    },
    ipAddress() {
      return this.contact.ip;
    },
    deviceName() {
      return this.contact.device_name;
    },
  },
};
</script>
<style scoped lang="scss">
.conversation--attribute {
  @apply border-slate-50 dark:border-slate-700 border-b border-solid;

  &:nth-child(2n) {
    @apply bg-slate-25 dark:bg-slate-800;
  }
}
</style>
