<template>
  <div>
    <h3 v-if="!isUpgrade" class="text-base font-semibold leading-7 text-black-900">{{ $t('PAYMENT_MGMT.PLANS.TITLE') }}</h3>
    <p v-if="!isUpgrade" class="mt-1 mb-6 max-w-2xl text-sm leading-6 text-black-600">{{ $t('PAYMENT_MGMT.PLANS.DESCRIPTION') }}</p>
    <div v-if="isUpgrade" class="mb-6">
      <h3 class="text-base font-semibold leading-7 text-black-900">{{ $t('PAYMENT_MGMT.PLANS.CURRENT.TITLE') }}</h3>
      <p class="mt-2 max-w-2xl text-sm leading-4 text-black-600">{{ $t('PAYMENT_MGMT.PLANS.CURRENT.COUNT') }}: {{ currentInfo.userCount }}</p>
      <p class="max-w-2xl text-sm leading-4 text-black-600">{{ $t('PAYMENT_MGMT.PLANS.CURRENT.PLAN') }}: {{ currentInfo.planName }}</p>
      <p class="max-w-2xl text-sm leading-4 text-black-600">{{ $t('PAYMENT_MGMT.PLANS.CURRENT.EXPIRE_TIME') }}: {{ expireDate(currentInfo.endAt, getLocale) }}</p>
    </div>

    <!-- User Count Input -->
    <label class="text-base font-semibold leading-6 text-black-900">{{ countText }}</label>
    <div class="mt-2">
      <input
        :id="userCount"
        v-model.number="userCount"
        type="number"
        :placeholder="$t('PAYMENT_MGMT.PLANS.Count_placeholder')"
        class="block max-w-xs mb-1 rounded-md border-0 py-1.5 text-black-900 shadow-sm ring-1 ring-inset ring-black-300 placeholder:text-black-400 focus:ring-2 focus:ring-inset focus:ring-woot-600 sm:text-lg sm:leading-6"
        :min="minUserCount"
        :max="1000"
        @input="onUserCountInput"
      />
      <p v-dompurify-html="user_count_notice" class="text-sm text-black-600" />
    </div>

    <label class="mt-6 text-base font-semibold leading-6 text-black-900">{{ selectPlanText }}</label>
    <div id="plan-selector" class="max-w-md mt-4 grid grid-cols-1 gap-y-4">
      <div
        v-for="plan in canShowPlanList"
        :key="plan.id"
        class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
        :class="{'border-woot-600 ring-2 ring-woot-600': selectedPlan.id === plan.id, 'border-black-300': selectedPlan.id !== plan.id}"
        @click="selectPlan(plan)"
      >
        <div class="flex flex-1">
          <div class="flex flex-col">
            <div>
              <span class="text-md font-bold text-black-900">{{ plan.name }}</span>
              <span v-if="plan.id == 2" class="inline-flex items-center rounded-full bg-woot-50 px-2 py-1 mx-1 text-xs font-medium text-woot-700 ring-1 ring-inset ring-woot-700/10">{{ $t('PAYMENT_MGMT.PLANS.featured') }}</span>
              <span v-if="isUpgrade && plan.name == currentInfo.planName" class="inline-flex items-center rounded-full bg-woot-50 px-2 py-1 mx-1 text-xs font-medium text-woot-700 ring-1 ring-inset ring-woot-700/10">{{ $t('PAYMENT_MGMT.PLANS.CURRENT.PLAN') }}</span>
            </div>
            <div v-if="!isUpgrade && !!plan.discount_price" class="mt-1 flex items-center text-sm text-black-600">
              <span class="pr-2 line-through">{{ plan.unit_amount * userCount }}</span>
              <span class="pr-2 font-bold text-red-500">{{ plan.discount_price * userCount }}</span>
              {{ plan.currency }} {{ $t('PAYMENT_MGMT.PLANS.per-year') }}
            </div>
            <div v-else-if="!isUpgrade" class="mt-1 flex items-center text-sm text-black-600">{{ plan.unit_amount * userCount }} {{ plan.currency }} {{ $t('PAYMENT_MGMT.PLANS.per-year') }}</div>
            <div v-else class="mt-1 flex items-center text-sm text-black-600">{{ $t('PAYMENT_MGMT.PLANS.DIFF') }}: {{ upgradePrice(plan) }} {{ plan.currency }}</div>
            <!-- <span class="mt-1 flex items-center text-sm text-black-500">{{ plan.name }}</span> -->
            <!-- TODO: 需要为 Plan 增加 description 和 featured? 字段，然后把写死的部分替换掉 -->
          </div>
        </div>
        <svg v-if="selectedPlan.id === plan.id" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="h-5 w-5 text-woot-600" fill="currentcolor" aria-hidden="true"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
        <span
          class="pointer-events-none absolute -inset-px rounded-lg"
          :class="{
            'border': selectedPlan.id === plan.id,
            'border-2': selectedPlan.id !== plan.id,
            'border-woot-600': selectedPlan.id === plan.id,
            'border-transparent': selectedPlan.id !== plan.id
          }"
          aria-hidden="true"
        />
      </div>
      <p class="text-sm text-black-600 -mt-1">
        {{ $t('PAYMENT_MGMT.PLANS.compare-detailed-plan-features-on') }}
        <a :href="pricingUrl" target="_blank">{{ $t('PAYMENT_MGMT.PLANS.the-pricing-page') }}</a>
      </p>
    </div>

    <!-- Select Button -->
    <woot-button
      class="mt-6 rounded-md bg-woot-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-woot-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-woot-600"
      :is-disabled="!canSubmit"
      @click="handleSubscription"
    >
      {{ $t('PAYMENT_MGMT.SELECT', {name: selectedPlan.name}) }}
    </woot-button>

    <div class="mt-8">
      <p class="mt-2 text-sm align-middle text-black-600">{{ $t('PAYMENT_MGMT.PLANS.payments') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import gtmEventMixin from 'shared/mixins/gtmEventMixin';
import timeMixin from 'dashboard/mixins/time';

export default {
  mixins: [alertMixin, gtmEventMixin, timeMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    currentInfo: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    planList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selectedPlan: this.currentInfo?.plan || this.planList[1],
      userCount: this.currentInfo?.userCount || 1,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getLocale: 'accounts/getLocale',
    }),
    user_count_notice() {
      return this.$t('PAYMENT_MGMT.PLANS.COUNT_DESC');
    },
    isUpgrade() {
      return this.type === 'upgrade';
    },
    countText() {
      if (this.type === 'upgrade') {
        return this.$t('PAYMENT_MGMT.PLANS.UPGRADE_COUNT');
      }
      return this.$t('PAYMENT_MGMT.PLANS.COUNT');
    },
    selectPlanText() {
      if (this.type === 'upgrade') {
        return this.$t('PAYMENT_MGMT.PLANS.UPGRADE_PLAN');
      }
      return this.$t('PAYMENT_MGMT.PLANS.select-a-plan');
    },
    minUserCount() {
      if (this.type === 'upgrade') {
        return this.currentInfo.userCount;
      }
      return 1;
    },
    calcDaysLeft() {
      const endAt = new Date(this.currentInfo.endAt);
      const currentDate = new Date();
      const timeDifference = endAt - currentDate;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference;
    },
    canSubmit() {
      if (this.type === 'upgrade') {
        return this.selectedPlan.id !== this.currentInfo.plan.id
          || this.userCount !== this.currentInfo.userCount;
      }
      return true;
    },
    canShowPlanList() {
      return this.planList.filter((plan) => this.canPlanShow(plan.id));
    },
    pricingUrl() {
      return window.chatwootConfig.mainURL + '/pricing';
    },
  },
  methods: {
    canPlanShow(planId) {
      if (this.type === 'upgrade') {
        return planId >= this.currentInfo.plan.id;
      }
      return true;
    },
    onUserCountInput() {
      this.userCount = Math.min(Math.max(this.userCount, this.minUserCount), 1000);
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    upgradePrice(plan) {
      const new_unit_price = plan.discount_price || plan.unit_amount;
      const old_unit_price = this.currentInfo.plan.discount_price || this.currentInfo.plan.unit_amount;
      const new_value_needed = new_unit_price * this.userCount;
      const old_value_left = old_unit_price * this.currentInfo.userCount;
      const diff = Math.ceil((this.calcDaysLeft / 365.0) * (new_value_needed - old_value_left));
      return diff;
    },
    async handleSubscription() {
      try {
        const subscriptionObj = {
          plan_id: this.selectedPlan.id,
          user_count: this.userCount
        };
        if (this.type === 'upgrade') {
          await this.$store.dispatch('payment/createOrder', {
            new_plan_id: this.selectedPlan.id,
            new_user_count: this.userCount
          });
        } else {
          await this.$store.dispatch('payment/createSubscription', subscriptionObj);
        }
        this.$router.push({
          name: 'payment_usdt',
          params: { accountId: this.$route.params.accountId },
        });
        // GTM Event for successful subscription
        this.sendGtmEvent({
          eventType: 'create_subscription',
          eventData: {
            plan_name: this.selectedPlan.name,
            agent_count: this.userCount,
            account_id: this.accountId
          }
        });
      } catch (error) {
        const errorMessage = error?.message || this.$t('PAYMENT_MGMT.SUBSCRIBE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
        // GTM Event for subscription error
        this.sendGtmEvent({
          eventType: 'create_subscription_error',
          eventData: {
            error_message: errorMessage,
            plan_name: this.selectedPlan.name,
            agent_count: this.userCount,
            account_id: this.accountId
          }
        });
      }
    }
  }
};
</script>
