import SettingsContent from '../Wrapper';
import CannedHome from './Index';
import AddCanned from './AddCanned';
import EditCanned from './EditCanned';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/canned-response'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name === 'canned_new' || params.name === 'canned_edit';
        return {
          headerTitle: 'CANNED_MGMT.HEADER',
          icon: 'chat-multiple',
          showNewButton: false,
          showBackButton
        };
      },
      children: [
        {
          path: '',
          name: 'canned_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'canned_list',
          roles: ['administrator', 'agent'],
          component: CannedHome,
        },
        {
          path: 'new',
          name: 'canned_new',
          roles: ['administrator', 'agent'],
          component: AddCanned,
        },
        {
          path: ':cannedId/edit',
          name: 'canned_edit',
          roles: ['administrator', 'agent'],
          component: EditCanned,
        }
      ],
    },
  ],
};
