import Vue from 'vue';
import types from '../mutation-types';
import BlacklistContactsAPI from '../../api/blacklist_contacts';
import BlacklistIpsAPI from '../../api/blacklist_ips';

const state = {
  meta: {
    count: 0,
    currentPage: 1,
  },
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
  sortOrder: [],
  appliedFilters: [],
};

export const getters = {
  getBlacklist($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getMeta($state) {
    return $state.meta;
  },
};

export const actions = {
  getBlacklistContacts: async ({ commit }, { page }) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: true });
    try {
      const { data: { payload, meta } } = await BlacklistContactsAPI.get(page);
      commit(types.CLEAR_BLACKLIST);
      commit(types.SET_BLACKLIST_CONTACT, payload);
      commit(types.SET_BLACKLIST_META, meta);
      commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: false });
    }
  },

  getBlacklistIps: async ({ commit }, { page }) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: true });
    try {
      const { data: { payload, meta } } = await BlacklistIpsAPI.get(page);
      commit(types.CLEAR_BLACKLIST);
      commit(types.SET_BLACKLIST_IP, payload);
      commit(types.SET_BLACKLIST_META, meta);
      commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_BLACKLIST_UI_FLAG, { isFetching: false });
    }
  },

  createBlacklistContact: async ({ commit }, { contactId }) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: true });
    try {
      await BlacklistContactsAPI.create({ contact_id: contactId });
      commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },

  createBlacklistIp: async ({ commit }, { contactId }) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: true });
    try {
      await BlacklistIpsAPI.create({ contact_id: contactId });
      commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_BLACKLIST_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },

  deleteBlacklistContacts: async ({ commit }, contactIds) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isDeleting: true });
    try {
      await BlacklistContactsAPI.destroyBlacklistContacts(contactIds);
      commit(types.REMOVE_BLACKLIST_CONTACT, contactIds);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BLACKLIST_UI_FLAG, { isDeleting: false });
    }
  },

  deleteBlacklistIps: async ({ commit }, ips) => {
    commit(types.SET_BLACKLIST_UI_FLAG, { isDeleting: true });
    try {
      await BlacklistIpsAPI.destroyBlacklistIps(ips);
      commit(types.REMOVE_BLACKLIST_IP, ips);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BLACKLIST_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_BLACKLIST_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_BLACKLIST_CONTACT]: ($state, data) => {
    $state.records = data.map(black_contact => {
      return {
        ...black_contact,
        display_name: black_contact.contact_name,
        type: 'contact',
      };
    });
  },

  [types.SET_BLACKLIST_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },

  [types.SET_BLACKLIST_IP]: ($state, data) => {
    $state.records = data.map(black_ip => {
      return {
        ...black_ip,
        display_name: black_ip.ip,
        type: 'ip',
      };
    });
  },

  [types.CLEAR_BLACKLIST]: $state => {
    Vue.set($state, 'records', []);
    Vue.set($state, 'sortOrder', []);
  },

  [types.REMOVE_BLACKLIST_CONTACT]: ($state, contactIds) => {
    $state.records = $state.records.filter(record => !contactIds.includes(record.contact_id));
  },

  [types.REMOVE_BLACKLIST_IP]: ($state, ips) => {
    $state.records = $state.records.filter(record => !ips.includes(record.ip));
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
