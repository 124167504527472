<template>
  <div :class="emptyClassName">
    <woot-loading-state
      v-if="uiFlags.isFetching || loadingChatList"
      :message="loadingIndicatorMessage"
    />

    <div v-if="introductoryStage && isAdmin" class="clearfix">
      <onboarding-view :inbox-id="showOnBoardingInboxId" />
    </div>

    <div
      v-else-if="!inboxesList.length && !uiFlags.isFetching && !loadingChatList"
      class="clearfix"
    >
      <empty-state-message
        :message="$t('CONVERSATION.NO_INBOX_AGENT')"
      />
    </div>

    <!-- Show empty state images if not loading -->

    <div
      v-else-if="!uiFlags.isFetching && !loadingChatList"
      class="flex flex-col items-center justify-center h-full"
    >
      <!-- No conversations available -->
      <empty-state-message
        v-if="!allConversations.length"
        :message="$t('CONVERSATION.NO_MESSAGE_1')"
      />
      <empty-state-message
        v-else-if="allConversations.length && !currentChat.id"
        :message="conversationMissingMessage"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';
import OnboardingView from '../OnboardingView';
import EmptyStateMessage from './EmptyStateMessage';

export default {
  components: {
    OnboardingView,
    EmptyStateMessage,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isOnExpandedLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      uiFlags: 'inboxes/getUIFlags',
      loadingChatList: 'getChatListLoadingStatus',
      getInbox: 'inboxes/getInbox',
    }),
    loadingIndicatorMessage() {
      if (this.uiFlags.isFetching) {
        return this.$t('CONVERSATION.LOADING_INBOXES');
      }
      return this.$t('CONVERSATION.LOADING_CONVERSATIONS');
    },
    conversationMissingMessage() {
      if (!this.isOnExpandedLayout) {
        return this.$t('CONVERSATION.SELECT_A_CONVERSATION');
      }
      return this.$t('CONVERSATION.404');
    },
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new');
    },
    isWebsiteInboxWithNoConversation() {
      return !this.allConversations.length
      && ((!!this.inboxId && this.getInbox(this.inboxId).channel_type === 'Channel::WebWidget')
         || !this.inboxId
      );
    },
    introductoryStage() {
      return (!this.inboxesList.length || this.isWebsiteInboxWithNoConversation)
      && !this.uiFlags.isFetching
      && !this.loadingChatList;
    },
    emptyClassName() {
      if (this.introductoryStage && this.isAdmin) {
        return 'h-full overflow-auto';
      }
      return 'flex-1 min-w-0 px-0 flex flex-col items-center justify-center h-full';
    },
    showOnBoardingInboxId() {
      if (!this.inboxId && this.inboxesList.length) {
        // 找一个 web 的渠道
        const inbox = this.inboxesList.find(i => i.channel_type === 'Channel::WebWidget');
        if (inbox) {
          return inbox.id;
        }
        return undefined;
      }
      return this.inboxId;
    }
  },
};
</script>
