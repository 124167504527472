import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export const getInboxSource = (type, phoneNumber, inbox) => {
  switch (type) {
    case INBOX_TYPES.WEB:
      return inbox.website_url || '';

    case INBOX_TYPES.TWILIO:
    case INBOX_TYPES.WHATSAPP:
      return phoneNumber || '';

    case INBOX_TYPES.EMAIL:
      return inbox.email || '';

    default:
      return '';
  }
};
export const getReadableInboxByType = (type, phoneNumber) => {
  switch (type) {
    case INBOX_TYPES.WEB:
      return 'livechat';

    case INBOX_TYPES.FB:
      return 'facebook';

    case INBOX_TYPES.TWITTER:
      return 'twitter';

    case INBOX_TYPES.TWILIO:
      return phoneNumber?.startsWith('whatsapp') ? 'whatsapp' : 'sms';

    case INBOX_TYPES.WHATSAPP:
      return 'whatsapp';

    case INBOX_TYPES.API:
      return 'api';

    case INBOX_TYPES.EMAIL:
      return 'email';

    case INBOX_TYPES.TELEGRAM:
      return 'telegram';

    case INBOX_TYPES.TELEGRAM_MT:
      return 'telegram_mt';

    case INBOX_TYPES.LINE:
      return 'line';

    default:
      return 'chat';
  }
};

export const getInboxClassByType = (type, phoneNumber) => {
  switch (type) {
    case INBOX_TYPES.WEB:
      return 'globe-desktop';

    case INBOX_TYPES.FB:
      return 'brand-facebook';

    case INBOX_TYPES.TWITTER:
      return 'brand-twitter';

    case INBOX_TYPES.TWILIO:
      return phoneNumber?.startsWith('whatsapp')
        ? 'brand-whatsapp'
        : 'brand-sms';

    case INBOX_TYPES.WHATSAPP:
      return 'brand-whatsapp';

    case INBOX_TYPES.API:
      return 'cloud';

    case INBOX_TYPES.EMAIL:
      return 'mail';

    case INBOX_TYPES.TELEGRAM:
      return 'brand-telegram';

    case INBOX_TYPES.TELEGRAM_MT:
      return 'brand-telegram';

    case INBOX_TYPES.LINE:
      return 'brand-line';

    default:
      return 'chat';
  }
};

export const getInboxWarningIconClass = (type, reauthorizationRequired) => {
  if (type === INBOX_TYPES.FB && reauthorizationRequired) {
    return 'warning';
  }
  return '';
};

export const generateRandomSubDomain = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  let randomLetters = '';
  for (let i = 0; i < 3; i += 1) {
    randomLetters += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  const numbers = '0123456789';
  let randomNumbers = '';
  for (let i = 0; i < 4; i += 1) {
    randomNumbers += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }
  return randomLetters + '-' + randomNumbers;
};

export const buildWidgetLink = (randSubDomain, planId) => {
  const subdomain = randSubDomain || '*****';
  if (planId > 0) {
    return `https://${subdomain}.${window.chatwootConfig.premium_widget_domain}`;
  }
  // 免费版的
  return `https://${subdomain}.${window.chatwootConfig.free_widget_domain}`;
};
