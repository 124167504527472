<template>
  <banner
    v-if="shouldShowBanner"
    color-scheme="primary"
    :banner-message="bannerMessage"
    :action-button-label="actionButtonMessage"
    has-action-button
    @click="routeToBilling"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';

export default {
  components: { Banner },
  mixins: [adminMixin, accountMixin],
  data() {
    return { conversationMeta: {} };
  },
  computed: {
    ...mapGetters({
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      getAccount: 'accounts/getAccount',
      isFreeVersion: 'payment/isFreeVersion',
      isPaymentPending: 'payment/isPaymentPending',
    }),
    bannerMessage() {
      return this.$t('GENERAL_SETTINGS.LIMITS_UPGRADE');
    },
    actionButtonMessage() {
      return this.$t('GENERAL_SETTINGS.OPEN_BILLING');
    },
    shouldShowBanner() {
      // to avoid both banners displaying, check no payment pending here
      if (this.isFreeVersion && !this.isPaymentPending) {
        return true;
      }

      return this.isLimitExceeded();
    },
  },
  mounted() {
    if (this.isOnChatwootCloud) {
      this.fetchLimits();
    }
  },
  methods: {
    fetchLimits() {
      this.$store.dispatch('accounts/limits');
    },
    routeToBilling() {
      if (this.$route.name !== 'payment_list') {
        this.$router.push({
          name: 'payment_list',
          params: { accountId: this.accountId },
        });
      }
    },
    isLimitExceeded() {
      const account = this.getAccount(this.accountId);
      if (!account) return false;

      const { limits } = account;
      if (!limits) return false;

      const { conversation, non_web_inboxes: nonWebInboxes } = limits;
      return this.testLimit(conversation) || this.testLimit(nonWebInboxes);
    },
    testLimit({ allowed, consumed }) {
      return consumed > allowed;
    },
  },
};
</script>
