export default {
  methods: {
    sendGtmEvent({ eventType, eventData }) {
      if (window.dataLayer) {
        window.dataLayer.push({
          'event': eventType,
          ...eventData
        });
      }
    }
  }
}
