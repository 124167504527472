/* global axios */
import ApiClient from '../ApiClient';

class TelegramMtChannel extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  create(params) {
    return axios.post(`${this.baseUrl()}/callbacks/telegram_mt_qr_login`, params);
  }
}

export default new TelegramMtChannel();
