import Vue from 'vue';
import types from '../mutation-types';
import {
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import ContactAPI from '../../api/contacts';

const state = {
  meta: {
    count: 0,
    currentPage: 1,
  },
  records: {},
  previousPageviews: [],
  uiFlags: {
    isFetching: false,
    isFetchingPreviousPageviews: false,
    isFetchingInboxes: false
  },
  sortOrder: [],
  appliedFilters: [],
};

export const getters = {
  getVisits($state) {
    return $state.sortOrder.map(contactId => $state.records[contactId]);
  },
  getPreviousPageviews($state) {
    return $state.previousPageviews;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getVisit: $state => id => {
    const visit = $state.records[id];
    return visit || {};
  },
  getMeta: $state => {
    return $state.meta;
  },
  getAppliedVisitFilters: $state => {
    return $state.appliedFilters;
  },
};

export const actions = {
  get: async ({ commit }, { page = 1, inboxId, sortAttr } = {}) => {
    commit(types.SET_VISIT_UI_FLAG, { isFetching: true });
    try {
      const { data: { payload, meta } } = await ContactAPI.activeSearch(page, inboxId, sortAttr);
      commit(types.CLEAR_VISITS);
      commit(types.SET_VISITS, payload);
      commit(types.SET_VISIT_META, meta);
      commit(types.SET_VISIT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_VISIT_UI_FLAG, { isFetching: false });
    }
  },

  filter: async ({ commit }, {
    page = 1, inboxId, queryPayload, sortAttr, resetState = true
  } = {}) => {
    commit(types.SET_VISIT_UI_FLAG, { isFetching: true });
    try {
      const { data: { payload, meta } } = await ContactAPI.activeFilter(page, inboxId, queryPayload, sortAttr);
      if (resetState) {
        commit(types.CLEAR_VISITS);
        commit(types.SET_VISITS, payload);
        commit(types.SET_VISIT_META, meta);
        commit(types.SET_VISIT_UI_FLAG, { isFetching: false });
      }
    } catch (error) {
      commit(types.SET_VISIT_UI_FLAG, { isFetching: false });
    }
  },

  previousPageviews: async ({ commit }, { inboxId, contactId } = {}) => {
    commit(types.SET_VISIT_UI_FLAG, { isFetchingPreviousPageviews: true });
    try {
      const { data: { payload } } = await ContactAPI.previousPageviews(inboxId, contactId);
      commit(types.SET_PAGEVIEWS, payload);
      commit(types.SET_VISIT_UI_FLAG, { isFetchingPreviousPageviews: false });
    } catch (error) {
      commit(types.SET_VISIT_UI_FLAG, { isFetchingPreviousPageviews: false });
    }
  },

  fetchContactableInbox: async ({ commit }, id) => {
    commit(types.SET_VISIT_UI_FLAG, { isFetchingInboxes: true });
    try {
      const response = await ContactAPI.getContactableInboxes(id);
      const visit = {
        id,
        contactableInboxes: response.data.payload,
      };
      commit(types.SET_VISIT_ITEM, visit);
    } catch (error) {
      if (error.response?.data?.message) {
        throw new ExceptionWithMessage(error.response.data.message);
      } else {
        throw new Error(error);
      }
    } finally {
      commit(types.SET_VISIT_UI_FLAG, { isFetchingInboxes: false });
    }
  },

  setVisitFilters({ commit }, data) {
    commit(types.SET_VISIT_FILTERS, data);
  },

  clearVisitFilters({ commit }) {
    commit(types.CLEAR_VISIT_FILTERS);
  },
};

export const mutations = {
  [types.SET_VISIT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.CLEAR_VISITS]: $state => {
    Vue.set($state, 'records', {});
    Vue.set($state, 'sortOrder', []);
  },

  [types.SET_VISIT_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },

  [types.SET_PAGEVIEWS]: ($state, data) => {
    $state.previousPageviews = data;
  },

  [types.SET_VISITS]: ($state, data) => {
    const sortOrder = data.map(contact => {
      Vue.set($state.records, contact.id, {
        ...($state.records[contact.id] || {}),
        ...contact,
      });
      return contact.id;
    });
    $state.sortOrder = sortOrder;
  },

  [types.SET_VISIT_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
    });

    if (!$state.sortOrder.includes(data.id)) {
      $state.sortOrder.push(data.id);
    }
  },

  [types.SET_VISIT_FILTERS]($state, data) {
    $state.appliedFilters = data;
  },

  [types.CLEAR_VISIT_FILTERS]($state) {
    $state.appliedFilters = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
