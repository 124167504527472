import en from './locale/en.json';
import zh_CN from './locale/zh_CN.json';
import zh_TW from './locale/zh_TW.json';
import vi from './locale/vi.json';
import th from './locale/th.json';
import ja from './locale/ja.json';
import ko from './locale/ko.json';
import es from './locale/es.json';
import fr from './locale/fr.json';
import de from './locale/de.json';
import pt from './locale/pt.json';
import id from './locale/id.json';
import it from './locale/it.json';

export default {
  en,
  zh_CN,
  zh_TW,
  vi,
  th,
  ja,
  ko,
  es,
  fr,
  de,
  pt,
  id,
  it,
};
