<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('ROBOTQAS_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List QA -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <p
          v-if="!uiFlags.isFetching && !records.length"
          class="flex h-full items-center flex-col justify-center"
        >
          {{ $t('ROBOTQAS_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ROBOTQAS_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.isFetching && records.length"
          class="woot-table"
        >
          <thead>
            <!-- Header -->
            <th
              v-for="thHeader in $t('ROBOTQAS_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(qaItem, index) in records"
              :key="qaItem.question"
            >
              <!-- Question -->
              <td class="w-[8.75rem]">
                {{ qaItem.question }}
              </td>
              <!-- Answer -->
              <td class="break-all whitespace-normal">
                {{ qaItem.answer }}
              </td>
              <!-- Action Buttons -->
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('ROBOTQAS_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[qaItem.id]"
                  @click="openDeletePopup(qaItem, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table-footer
          v-if="!uiFlags.isFetching && records.length"
          :current-page="meta.currentPage"
          :total-count="meta.count"
          :page-size="meta.pageSize"
          @page-change="onPageChange"
        />
      </div>

      <div class="w-[34%]">
        <span v-dompurify-html="$t('ROBOTQAS_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-qa :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Delete QA -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('ROBOTQAS_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('ROBOTQAS_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="''"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddQa from './AddQa';
import TableFooter from 'dashboard/components/widgets/TableFooter';

export default {
  components: {
    AddQa,
    TableFooter
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      qaResponseAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      records: 'robotQas/getRobotQas',
      uiFlags: 'robotQas/getUIFlags',
      meta: 'robotQas/getMeta',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('ROBOTQAS_MGMT.DELETE.CONFIRM.YES')}`;
    },
    deleteRejectText() {
      return `${this.$t('ROBOTQAS_MGMT.DELETE.CONFIRM.NO')}`;
    },
  },
  mounted() {
    this.fetchRobotQas(1);
  },
  methods: {
    // Fetch API Call
    fetchRobotQas(page) {
      this.$store.dispatch('robotQas/fetchRobotQas', { page });
    },
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.qaResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteRobotQa(this.selectedResponse.id);
    },
    deleteRobotQa(id) {
      this.$store
        .dispatch('robotQas/deleteRobotQa', id)
        .then(() => {
          this.showAlert(this.$t('ROBOTQAS_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(error => {
          const errorMessage = error?.message || this.$t('ROBOTQAS_MGMT.DELETE.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
    onPageChange(page) {
      this.fetchRobotQas(page);
    },
  },
};
</script>
