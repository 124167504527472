var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.show,"on-close":_vm.onClose},on:{"update:show":function($event){_vm.show=$event}}},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('TRANSLATE_SETTINGS.TITLE'),"header-content":_vm.$t('TRANSLATE_SETTINGS.DESC')}},[_c('span',{staticClass:"text-slate-600 mt-2 text-sm dark:text-slate-300"},[_vm._v(_vm._s(_vm.$t('TRANSLATE_SETTINGS.DESC2'))),_c('router-link',{attrs:{"to":{
        name: 'settings_inbox_show',
        params: { inboxId: this.currentConversation.inbox_id },
      }}},[_vm._v(_vm._s(_vm.$t('TRANSLATE_SETTINGS.LINK')))]),_vm._v(_vm._s(_vm.$t('TRANSLATE_SETTINGS.DESC3')))],1)]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.translateLang),expression:"translateLang"}],staticClass:"mb-2 w-1/2 mx-8 mt-4",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.translateLang=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":'disabled'}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AUTO_TRANSLATE.DISABLE'
        ))+"\n    ")]),_vm._v(" "),_c('option',{domProps:{"value":''}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AUTO_TRANSLATE.AUTO'
        ))+"\n    ")]),_vm._v(" "),_vm._l((_vm.languagesSortedByCode),function(lang){return _c('option',{key:lang.iso_639_1_code,domProps:{"value":lang.iso_639_1_code}},[_vm._v("\n      "+_vm._s(((lang.name) + "(" + (lang.iso_639_1_code) + ")"))+"\n    ")])})],2),_vm._v(" "),_c('div',{staticClass:"delete-item"},[_c('woot-button',{staticClass:"action-button",attrs:{"color-scheme":"primary","variant":"smooth"},on:{"click":_vm.onEnable}},[_vm._v("\n      "+_vm._s(_vm.$t('TRANSLATE_SETTINGS.SAVE'))+"\n    ")]),_vm._v(" "),_c('woot-button',{staticClass:"action-button",attrs:{"color-scheme":"link","variant":"smooth"},on:{"click":_vm.onDisable}},[_vm._v("\n      "+_vm._s(_vm.$t('TRANSLATE_SETTINGS.CANCEL'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }