<template>
  <div class="columns onboarding-wrap">
    <div class="onboarding">
      <div class="scroll-wrap">
        <div class="features-item">
          <h1 class="page-title">
            <span>{{
              $t('ONBOARDING.TITLE', {
                installationName: globalConfig.installationName,
              })
            }}</span>
          </h1>
          <p class="intro-body">
            {{
              $t('ONBOARDING.DESCRIPTION', {
                installationName: globalConfig.installationName,
              })
            }}
          </p>
          <p v-if="globalConfig.installationName === 'Chatwoot'">
            <a
              href="https://www.chatwoot.com/changelog"
              target="_blank"
              rel="noopener nofollow noreferrer"
              class="onboarding--link"
            >
              {{ $t('ONBOARDING.READ_LATEST_UPDATES') }}
            </a>
            <span>🎉</span>
          </p>
        </div>
        <div class="features-item get-inbox-domain">
          <h2 class="block-title text-black-900 dark:text-slate-200">
            <span class="emoji">🔗</span>
            <span class="conversation--title">{{
              $t('ONBOARDING.WIDGET_LINK.TITLE')
            }}</span>
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.WIDGET_LINK.DESCRIPTION') }}
          </p>
          <p class="intro-body font-bold">
            {{ $t('ONBOARDING.WIDGET_LINK.INPUT_DESC') }}<span v-if="getPlanId == 0">(</span><span v-if="getPlanId == 0" class="text-red-700">{{ $t('ONBOARDING.WIDGET_LINK.INPUT_DESC2') }}</span><router-link v-if="getPlanId == 0" :to="newPaymentsURL">{{ $t('ONBOARDING.WIDGET_LINK.INPUT_DESC3') }}</router-link><span v-if="getPlanId == 0">)</span>
          </p>
          <input :value="linkUrl" type="text" class="widget-link--input font-mono" readonly />
          <vue-qrcode :v-if="localInboxId" :value="linkUrl" class="pb-2" />
          <div class="check-this-info">
            <img src="/assets/images/dashboard/click-this.png" />
          </div>
          <button class="onboarding--link" @click="handleButtonClick">
            {{ localInboxId ? $t('ONBOARDING.WIDGET_LINK.BUTTON_DESC2') : $t('ONBOARDING.WIDGET_LINK.BUTTON_DESC') }}
          </button>
          <!-- <button @click="copyToClipboard">{{ $t('ONBOARDING.WIDGET_LINK.COPY_DESC') }}</button> -->
        </div>
        <div class="features-item">
          <h2 class="block-title text-black-900 dark:text-slate-200">
            <span class="emoji">📥</span>{{ $t('ONBOARDING.INBOXES.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.INBOXES.DESCRIPTION') }}
          </p>
          <router-link :to="newInboxURL" class="onboarding--link">
            {{ $t('ONBOARDING.INBOXES.NEW_LINK') }}
          </router-link>
        </div>
        <div class="features-item">
          <h2 class="block-title text-black-900 dark:text-slate-200">
            <span class="emoji">💳</span>{{ $t('ONBOARDING.PAYMENTS.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.PAYMENTS.DESCRIPTION') }}
          </p>
          <router-link :to="newPaymentsURL" class="onboarding--link">
            {{ $t('ONBOARDING.PAYMENTS.NEW_LINK') }}
          </router-link>
        </div>
        <div class="features-item">
          <h2 class="block-title text-black-900 dark:text-slate-200">
            <span class="emoji">🛟</span>
            {{ $t('ONBOARDING.HELPCENTER.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.HELPCENTER.DESCRIPTION') }}
          </p>
          <a :href="docUrl" target="_blank" class="onboarding--link">
            {{ $t('ONBOARDING.HELPCENTER.NEW_LINK') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import accountMixin from '../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import { generateRandomSubDomain } from 'dashboard/helper/inbox';
import router from '../../../routes';
import { buildWidgetLink } from '../../../helper/inbox';
import VueQrcode from 'qrcode.vue';

export default {
  components: {
    VueQrcode
  },
  mixins: [accountMixin, alertMixin],
  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
  },
  data() {
    return {
      localInboxId: this.inboxId,
      activatedInbox: undefined,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      account: 'getCurrentAccount',
      currentUser: 'getCurrentUser',
      getInbox: 'inboxes/getInbox',
      getPlanId: 'accounts/getPlanId',
    }),
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new/website');
    },
    newPaymentsURL() {
      return this.addAccountScoping('settings/payment/list');
    },
    linkUrl() {
      if (this.localInboxId) {
        const sub_domain = this.getInbox(this.localInboxId).rand_sub_domain;
        return buildWidgetLink(sub_domain, this.getPlanId);
      }
      return buildWidgetLink('', this.getPlanId);
    },
    docUrl() {
      return window.chatwootConfig.mainURL + '/docs';
    },
  },
  methods: {
    sanitizeName(name) {
      // Remove illegal characters
      let sanitized = name.replace(/[<>/\\@]/g, '');
      return sanitized;
    },
    async createChannel() {
      let subDomain = generateRandomSubDomain();
      try {
        const website = await this.$store.dispatch(
          'inboxes/createWebsiteChannel',
          {
            name: this.sanitizeName(this.account.name),
            greeting_enabled: false,
            channel: {
              type: 'web_widget',
              website_url: '',
              widget_color: '#009CE0',
              rand_sub_domain: subDomain,
            },
          }
        );
        // await this.$store.dispatch('inboxMembers/create', {
        //   inboxId: website.id,
        //   agentList: [this.currentUser.id]
        // });
        this.activatedInbox = website;
        this.localInboxId = website.id;
        // router.replace({
        //   name: 'settings_inbox_finish',
        //   params: {
        //     page: 'new',
        //     inbox_id: website.id,
        //   },
        // });
      } catch (error) {
        this.showAlert(
          error.message
            || this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
    handleButtonClick() {
      if (this.localInboxId) {
        router.replace({
          name: 'settings_inbox_show',
          params: {
            inboxId: this.localInboxId
          },
        });
      } else {
        this.createChannel();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.onboarding-wrap {
  display: flex;
  font-size: var(--font-size-small);
  justify-content: center;
  overflow: auto;
  text-align: left;
}
.onboarding {
  overflow: auto;
}

.scroll-wrap {
  padding: 6rem 8.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features-item {
  margin-bottom: var(--space-large);
}

.conversation--title {
  margin-left: var(--space-minus-smaller);
}

.page-title {
  font-size: var(--font-size-big);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

.block-title {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-smaller);
  margin-left: var(--space-minus-large);
}

.intro-body {
  margin-bottom: var(--space-one);
  line-height: 1.5;
}

.intro-body {
  margin-bottom: var(--space-one);
  line-height: 1.5;
}

.widget-link--input {
  @apply rounded-md font-semibold border-woot-600 cursor-default bg-white
}

.get-inbox-domain {
  @apply mt-8 mb-16
}
.get-inbox-domain .onboarding--link {
  @apply inline-block rounded-md cursor-pointer bg-woot-600 my-1 px-3.5 py-2.5 text-sm font-semibold
    text-white shadow-sm hover:bg-woot-500 focus-visible:outline focus-visible:outline-2
    focus-visible:outline-offset-2 focus-visible:outline-woot-600
}

.emoji {
  width: var(--space-large);
  display: inline-block;
}

.check-this-info {
  position: relative;
  user-select: none;
  img {
    position: absolute;
    height: 186px;
    width: 125px;
    left:-129px;
    top:-156px;
    user-select: none;
  }
}
</style>
