import { frontendURL } from '../../../../helper/URLHelper';

const visits = accountId => ({
  parentNav: 'visits',
  routes: [
    'visits_dashboard',
    'visits_inbox_dashboard',
  ],
  menuItems: [
    {
      icon: 'compass-northwest',
      label: 'ALL_VISITS',
      key: 'visits',
      toState: frontendURL(`accounts/${accountId}/visits_dashboard`),
      toolTip: 'Visits from all subscribed inboxes',
      toStateName: 'visits_dashboard',
    },
  ],
});

export default visits;
