<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
          <span v-if="$v.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>
      <div v-if="qrLoginUrl" class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] py-8">
        {{ $t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.QRCODE_DESC') }}
        <vue-qrcode :value="qrLoginUrl" />
      </div>
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import VueQrcode from 'qrcode.vue';

export default {
  components: {
    PageHeader,
    VueQrcode,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      qrLoginUrl: 'inboxes/getTelegramMtQrLoginUrl',
    }),
  },
  validations: {
    inboxName: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const telegramChannel = await this.$store.dispatch(
          'inboxes/createTelegramMtChannel',
          {
            inbox_name: this.inboxName
          }
        );

        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: telegramChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message ? error.message
            : this.$t('INBOX_MGMT.ADD.TELEGRAM_MT_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
