export const featurePlan = {
  channel_website: 0,
  channel_facebook: 2,
  channel_twitter: 2,
  channel_twilio: 2,
  channel_whatsapp: 2,
  channel_sms: 2,
  channel_email: 2,
  channel_api: 3,
  channel_telegram: 2,
  channel_telegram_mt: 2,
  channel_line: 2,
  file_upload: 2,
  automation: 2,
  macro: 2,
  working_hours: 2,
  contact_import_export: 2,
  download_report: 2,
  report_date_range: 2,
  report: 2,
  campaign: 2,
  webhook: 3,
  dashboard_apps: 2,
  openai: 3,
  dialog_flow: 3,
  dyte: 3,
  custom_domain_binding: 3,
};

export const featureLimit = {
  1: 100,
  2: 200
};
