<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] h-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DESC')"
    />
    <woot-loading-state
      v-if="uiFlags.isCreating"
      :message="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE')"
    />
    <form
      v-if="!uiFlags.isCreating"
      class="mx-0 flex flex-wrap"
      @submit.prevent="createChannel"
    >
      <div class="w-full">
        <label>
          {{ $t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER')"
          />
        </label>
      </div>

      <!-- <div class="w-full">
        <label>
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL') }}
          <input
            v-model.trim="channelWebsiteUrl"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER')
            "
          />
        </label>
      </div> -->

      <div class="w-full">
        <label>
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
          <woot-color-picker v-model="channelWidgetColor" />
        </label>
      </div>

      <div class="w-full">
        <label>
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL') }}
          <input
            v-model.trim="channelWelcomeTitle"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
              )
            "
          />
        </label>
      </div>
      <div class="w-full">
        <span class="text-sm font-medium text-slate-900 dark:text-white">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL') }}</span>
        <woot-article-editor
          v-model="channelWelcomeTagline"
          class="article-content"
          :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER')"
          :enabled-menu-options="customEditorMenuOptions"
          @focus="onFocus"
          @blur="onBlur"
          @input="onContentInput"
        />
      </div>
      <label class="w-full">
        {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL') }}
        <select v-model="greetingEnabled">
          <option :value="true">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
              )
            }}
          </option>
          <option :value="false">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
              )
            }}
          </option>
        </select>
        <p class="help-text">
          {{
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
            )
          }}
        </p>
      </label>
      <greetings-editor
        v-if="greetingEnabled"
        v-model.trim="greetingMessage"
        class="w-full"
        :label="
          $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL')
        "
        :placeholder="
          $t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
          )
        "
        :richtext="!textAreaChannels"
      />
      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <div class="w-full">
          <woot-submit-button
            :loading="uiFlags.isCreating"
            :disabled="!inboxName"
            :button-text="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.SUBMIT_BUTTON')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import GreetingsEditor from 'shared/components/GreetingsEditor';
import alertMixin from 'shared/mixins/alertMixin';
import { generateRandomSubDomain } from 'dashboard/helper/inbox';
import WootArticleEditor from 'dashboard/components/widgets/WootWriter/FullEditor.vue';
import { WEB_TAGLINE_EDITOR_MENU_OPTIONS } from 'dashboard/constants/editor';

export default {
  components: {
    PageHeader,
    GreetingsEditor,
    WootArticleEditor,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      channelWebsiteUrl: '',
      channelWidgetColor: '#009CE0',
      channelWelcomeTitle: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_WELCOME_TITLE'),
      channelWelcomeTagline: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_WELCOME_TAGLINE'),
      greetingEnabled: true,
      greetingMessage: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_GREETING'),
      customEditorMenuOptions: WEB_TAGLINE_EDITOR_MENU_OPTIONS,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      account: 'getCurrentAccount',
      inboxes: 'inboxes/getInboxes',
    }),
    textAreaChannels() {
      if (
        this.isATwilioChannel
        || this.isATwitterInbox
        || this.isAFacebookInbox
      ) return true;
      return false;
    },
    sanitizeName() {
      const account_name = this.account.name;
      let names = this.inboxes.map(x => x.name);
      // Remove illegal characters
      let sanitized = account_name.replace(/[<>/\\@]/g, '');
      let final_name = sanitized;

      // Initialize a counter
      let counter = 1;

      // Keep checking and incrementing the counter until a unique name is found
      while (names.includes(final_name)) {
        counter += 1;
        final_name = sanitized + counter;
      }

      return final_name;
    },
  },
  created() {
    this.inboxName = this.sanitizeName;
  },
  methods: {
    async createChannel() {
      try {
        const website = await this.$store.dispatch(
          'inboxes/createWebsiteChannel',
          {
            name: this.inboxName,
            greeting_enabled: this.greetingEnabled,
            greeting_message: this.greetingMessage,
            channel: {
              type: 'web_widget',
              website_url: this.channelWebsiteUrl,
              widget_color: this.channelWidgetColor,
              welcome_title: this.channelWelcomeTitle,
              welcome_tagline: this.channelWelcomeTagline,
              rand_sub_domain: generateRandomSubDomain(),
            },
          }
        );
        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: website.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message
            || this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
    onFocus() {
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onContentInput() {
    },
  },
};
</script>
<style lang="scss" scoped>
.article-content {
  @apply py-0 px-4 border border-solid border-slate-200 dark:border-slate-600 rounded-md shadow-none;
}
</style>
