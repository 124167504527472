<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="$t('TRANSLATE_SETTINGS.TITLE')"
      :header-content="$t('TRANSLATE_SETTINGS.DESC')"
    >
      <span class="text-slate-600 mt-2 text-sm dark:text-slate-300">{{ $t('TRANSLATE_SETTINGS.DESC2') }}<router-link
        :to="{
          name: 'settings_inbox_show',
          params: { inboxId: this.currentConversation.inbox_id },
        }"
      >{{ $t('TRANSLATE_SETTINGS.LINK') }}</router-link>{{ $t('TRANSLATE_SETTINGS.DESC3') }}</span>
    </woot-modal-header>
    <select v-model="translateLang" class="mb-2 w-1/2 mx-8 mt-4">
      <option :value="'disabled'">
        {{
          $t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AUTO_TRANSLATE.DISABLE'
          )
        }}
      </option>
      <option :value="''">
        {{
          $t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AUTO_TRANSLATE.AUTO'
          )
        }}
      </option>
      <option
        v-for="lang in languagesSortedByCode"
        :key="lang.iso_639_1_code"
        :value="lang.iso_639_1_code"
      >
        {{ `${lang.name}(${lang.iso_639_1_code})` }}
      </option>
    </select>
    <div class="delete-item">
      <woot-button
        color-scheme="primary"
        class="action-button"
        variant="smooth"
        @click="onEnable"
      >
        {{ $t('TRANSLATE_SETTINGS.SAVE') }}
      </woot-button>
      <woot-button
        color-scheme="link"
        class="action-button"
        variant="smooth"
        @click="onDisable"
      >
        {{ $t('TRANSLATE_SETTINGS.CANCEL') }}
      </woot-button>
    </div>
  </modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '../../Modal';
import configMixin from 'shared/mixins/configMixin';

export default {
  components: {
    Modal,
  },
  mixins: [configMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {}
    },
  },
  computed: {
    ...mapGetters({
      currentConversation: 'getSelectedChat',
    }),
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguagesForWidget].map(i => ({
        name: this.$t(`FOREIGN_LANGUAGE_NAMES.${i.iso_639_1_code}`),
        iso_639_1_code: i.iso_639_1_code
      }));
      return enabledLanguages;
    },
    translateLang: {
      get() {
        return this.currentConversation.translate_lang;
      },
      set(newValue) {
        this.$store.dispatch('setTranslateLang', {
          conversationId: this.currentConversation.id,
          translateLang: newValue,
        });
      },
    },
  },
  methods: {
    onEnable() {
      this.onClose();
    },

    onDisable() {
      this.$store.dispatch('setTranslateLang', {
        conversationId: this.currentConversation.id,
        translateLang: 'disabled',
      });
      this.onClose();
    },

  }
};
</script>
<style lang="scss" scoped>
.action-button {
  max-width: var(--space-giga);
}
</style>
