/* global axios */
import ApiClient from './ApiClient';

class BlacklistContactsAPI extends ApiClient {
  constructor() {
    super('blacklist_contacts', { accountScoped: true });
  }

  get(page) {
    let requestURL = `${this.url}?page=${page}`;
    return axios.get(requestURL);
  }

  destroyBlacklistContacts(contactIds) {
    return axios.delete(`${this.url}`, {
      data: {
        contact_ids: contactIds
      }
    });
  }
}

export default new BlacklistContactsAPI();
