/* global axios */
import ApiClient from './ApiClient';

class RobotQasAPI extends ApiClient {
  constructor() {
    super('robot_qas', { accountScoped: true });
  }

  get(page) {
    let requestURL = `${this.url}?page=${page}`;
    return axios.get(requestURL);
  }
}

export default new RobotQasAPI();
