<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-between w-full py-2 px-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <woot-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden
          whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex gap-2">
        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('VISITS_PAGE.FILTER_VISITS_EDIT') }}
          </woot-button>
          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('VISITS_PAGE.FILTER_VISITS_DELETE') }}
          </woot-button>
        </div>
        <div v-if="!hasActiveSegments" class="relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full"
          />
          <woot-button
            class="clear"
            color-scheme="secondary"
            data-testid="toggle_filter"
            icon="filter"
            @click="toggleFilter"
          >
            {{ $t('VISITS_PAGE.FILTER_VISITS') }}
          </woot-button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  mixins: [adminMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getAppliedVisitFilters: 'visits/getAppliedVisitFilters',
      isFeatureEnabledonPlan: 'accounts/isFeatureEnabledonPlan',
    }),
    hasAppliedFilters() {
      return this.getAppliedVisitFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
  },
  methods: {
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
  },
};
</script>
