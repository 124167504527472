import { render, staticRenderFns } from "./AttachmentsPreview.vue?vue&type=template&id=6ff6526b&scoped=true&"
import script from "./AttachmentsPreview.vue?vue&type=script&lang=js&"
export * from "./AttachmentsPreview.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentsPreview.vue?vue&type=style&index=0&id=6ff6526b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff6526b",
  null
  
)

export default component.exports