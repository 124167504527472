/* eslint arrow-body-style: 0 */
import VisitsView from './VisitsView';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/visits_dashboard'),
      name: 'visits_dashboard',
      roles: ['administrator', 'agent'],
      component: VisitsView,
      props: () => {
        return { inboxId: '' };
      },
    },
    {
      path: frontendURL('accounts/:accountId/inbox/:inbox_id/visits'),
      name: 'visits_inbox_dashboard',
      roles: ['administrator', 'agent'],
      component: VisitsView,
      props: route => {
        return { inboxId: route.params.inbox_id };
      },
    },
  ],
};
