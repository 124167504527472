<template>
  <div class="contact-conversation--panel">
    <div v-if="!uiFlags.isFetchingPreviousPageviews" class="contact-conversation__wrap">
      <div v-if="!getPreviousPageviews.length" class="no-label-message">
        <span>
          {{ $t('CONTACT_PANEL.CONVERSATIONS.NO_RECORDS_FOUND') }}
        </span>
      </div>
      <div v-else class="contact-conversation--list">
        <visit-page-view-item
          v-for="pageview in getPreviousPageviews"
          :key="pageview.id"
          :pageview="pageview"
        />
      </div>
    </div>
    <spinner v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import VisitPageViewItem from './VisitPageViewItem';

export default {
  components: {
    Spinner,
    VisitPageViewItem,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
    inboxId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'visits/getUIFlags',
      getPreviousPageviews: 'visits/getPreviousPageviews',
    }),
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.$store.dispatch('visits/previousPageviews', {
          contactId: newContactId,
          inboxId: this.inboxId
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch('visits/previousPageviews', {
      contactId: this.contactId,
      inboxId: this.inboxId
    });
  },
};
</script>

<style lang="scss" scoped>
.no-label-message {
  @apply text-slate-500 dark:text-slate-400 mb-4;
}

::v-deep .conversation {
  @apply pr-0;
  .conversation--details {
    @apply pl-2;
  }
}
</style>
