<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-loading-state
      v-if="isLoading || isFetching"
      :message="$t('PAYMENT_MGMT.LOADING')"
    />
    <div v-else>
      <div class="flex flex-col">
        <div v-if="latestPaymentUnpaid">
          <WaitingPayment
            :latest-payment="payments[0]"
            :handle-crypto-payment="handleCryptoPayment"
            :payment-plan-name="paymentPlanName"
          />
        </div>
        <div v-if="isPaymentSuccess && !latestPaymentUnpaid">
          <p class="text-xl"><b> {{ $t('PAYMENT_MGMT.CREATE.API.SUCCESS_MESSAGE') }} </b></p>
        </div>
        <!-- <div v-if="isFreeVersion">
          <p>{{ $t('PAYMENT_MGMT.FREE_VERSION.CONV_MARGIN_DESC') }}: {{ convCount }}/100 </p>
        </div> -->
        <div v-if="isFreeVersion">
          <plan-list :plan-list="planList" type="new_sub" />
        </div>
        <div v-if="!isFreeVersion">
          <subscription
            :plan-name="currentPlan.name"
            :currency="currentPlan.currency"
            :unit-amount="currentPlan.discount_price || currentPlan.unit_amount"
            :user-count="subscriptions[0].user_count"
            :status="subscriptions[0].status"
            :end-at="subscriptions[0].end_at"
          />
          <woot-button
            v-if="!latestPaymentUnpaid"
            color-scheme="success"
            icon="add-circle"
            @click="handleRenew"
          >
            {{ $t('PAYMENT_MGMT.SUBSCRIPTION.RENEW_BTN_TXT') }}
          </woot-button>
        </div>
        <!-- Payment List -->
        <div v-if="filteredPayments.length" class="mt-4">
          <PaymentList :filtered-payments="filteredPayments" :payment-plan-name="paymentPlanName" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import WaitingPayment from './WaitingPayment.vue';
import Subscription from './Subscription';
import PlanList from './PlanList';
import PaymentList from './PaymentList.vue';
import alertMixin from 'shared/mixins/alertMixin';
import gtmEventMixin from 'shared/mixins/gtmEventMixin';

export default {
  components: {
    WaitingPayment,
    Subscription,
    PlanList,
    PaymentList
  },
  mixins: [alertMixin, gtmEventMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('payment/fetchSubscriptionAndPayment');
    });
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapGetters({
      payments: 'payment/getPayments',
      subscriptions: 'payment/getSubscriptions',
      planList: 'payment/getPlanList',
      convCount: 'payment/getConvCount',
      uiFlags: 'payment/getUIFlags',
      isFreeVersion: 'payment/isFreeVersion',
      isFetching: 'payment/isFetching'
    }),
    latestPaymentUnpaid() {
      return this.payments.length > 0 && this.payments[0].paid_at == null;
    },
    currentPlan() {
      if (this.subscriptions.length > 0) {
        const planId = this.subscriptions[0].plan_id;
        return this.planList.find(plan => plan.id === planId) || null;
      }
      return null;
    },
    filteredPayments() {
      return this.payments.filter(payment => payment.paid_at !== null);
    },
    isPaymentSuccess() {
      if (this.$route.query.success) {
        this.sendGtmEvent({
          eventType: 'payment_success',
          eventData: {
            payment_method: this.payments[0].payment_method,
            payment_amount: this.payments[0].total_amount,
          }
        });
      }
      return this.$route.query.success;
    }
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch('payment/fetchPlanList'),
        this.$store.dispatch('payment/fetchConvCount')
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(['payment/createOrder']),
    async handleRenew() {
      try {
        await this.$store.dispatch('payment/createOrder');
      } catch (error) {
        const errorMessage = error?.message || this.$t('PAYMENT_MGMT.CREATE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async handleCryptoPayment() {
      const orderData = {};
      try {
        this.sendGtmEvent({
          eventType: 'click_payment_btn',
          eventData: orderData
        });
        this.$router.push({
          name: 'payment_usdt',
          params: { accountId: this.$route.params.accountId },
        });
      } catch (error) {
        const errorMessage = error?.message || '';
        this.showAlert(errorMessage);
        this.sendGtmEvent({
          eventType: 'click_payment_btn_error',
          eventData: {
            ...orderData,
            error_message: errorMessage,
          }
        });
      }
    },
    paymentPlanName(planId) {
      const p = this.planList.find(plan => plan.id === planId) || null;
      return p ? p.name : '';
    }
  }
};
</script>
