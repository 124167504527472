<template>
  <div class="flex flex-col items-start pt-8 px-8 pb-0">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <h2
      ref="modalHeaderTitle"
      class="text-slate-800 text-lg dark:text-slate-100"
    >
      {{ headerTitle }}
    </h2>
    <div
      v-if="headerContent"
      ref="modalHeaderContent"
      class="w-full break-words text-slate-600 mt-2 text-sm dark:text-slate-300"
    >
      <span v-if="showHtml" v-html="headerContent" />
      <span v-else>{{ headerContent }}</span>
      <span
        v-if="headerContentValue"
        class="font-semibold text-sm text-slate-600 dark:text-slate-300"
      >
        {{ headerContentValue }}
      </span>
    </div>
    <p
      v-if="headerContent2"
      ref="modalHeaderContent2"
      class="w-full break-words text-slate-600 mt-2 text-sm dark:text-slate-300"
    >
      {{ headerContent2 }}
      <span
        v-if="headerContentValue2"
        class="font-semibold text-sm text-slate-600 dark:text-slate-300"
      >
        {{ headerContentValue2 }}
      </span>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    headerContent2: {
      type: String,
      default: '',
    },
    headerContentValue2: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
    showHtml: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
