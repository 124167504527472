import { throwErrorMessage } from 'dashboard/store/utils/api';
import * as types from '../mutation-types';
import PaymentAPI from '../../api/payment';
import SubscriptionAPI from '../../api/subscription';
import ConversationAPI from '../../api/conversations';

const state = {
  records: [],
  convCount: 0,
  subscriptions: [],
  payments: [],
  usdtData: {},
  uiFlags: {
    isFetching: true,
    isFetchingPlan: false,
    isLoadingUsdtAddress: false,
    paying: false
  }
};

const getters = {
  getPlanList($state) {
    return $state.records;
  },
  getSubscriptions($state) {
    return $state.subscriptions;
  },
  getPayments($state) {
    return $state.payments;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getConvCount($state) {
    return $state.convCount;
  },
  getUsdtData($state) {
    return $state.usdtData;
  },
  isFreeVersion($state) {
    return !$state.uiFlags.isFetching
      && ($state.subscriptions.length === 0 || $state.subscriptions[0].status === 'ended');
  },
  isFreeVersionStrict($state) {
    return !$state.uiFlags.isFetching
      && ($state.subscriptions.length === 0 || $state.subscriptions[0].status !== 'active');
  },
  isPaymentPending($state) {
    return $state.payments.length > 0 && $state.payments[0].paid_at == null;
  },
  isFetching($state) {
    return $state.uiFlags.isFetching;
  },
};

const actions = {
  fetchPlanList: async ({ commit }) => {
    commit(types.default.SET_PAYMENT_UI_FLAG, { isFetchingPlan: true });
    try {
      const response = await PaymentAPI.getPlanList();
      commit(types.default.SET_PLANLIST, response.data.payload);
      commit(types.default.SET_PAYMENT_UI_FLAG, { isFetchingPlan: false });
    } catch (error) {
      commit(types.default.SET_PAYMENT_UI_FLAG, { isFetchingPlan: false });
    }
  },
  fetchSubscriptionAndPayment: async ({ commit }) => {
    commit(types.default.SET_PAYMENT_UI_FLAG, { isFetching: true });
    try {
      const response = await PaymentAPI.getSubscription();
      commit(types.default.SET_SUBSCRIPTIONS, response.data.payload);
      const paymentResponse = await PaymentAPI.getPayments();
      commit(types.default.SET_PAYMENTS, paymentResponse.data.payload);
      commit(types.default.SET_PAYMENT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.default.SET_PAYMENT_UI_FLAG, { isFetching: false });
    }
  },
  createSubscription: async ({ commit }, subscriptionObj) => {
    const response = await SubscriptionAPI.create(subscriptionObj);
    commit(types.default.SET_SUBSCRIPTION, response.data.subscription);
    commit(types.default.ADD_PAYMENT, response.data.payment);
    return response.data;
  },
  makePayment: async function makePayment({ commit }, paymentObj) {
    commit(types.default.SET_PAYMENT_UI_FLAG, { isLoadingUsdtAddress: true });
    try {
      const response = await PaymentAPI.makePayment(paymentObj);
      commit(types.default.SET_PAYMENT_UI_FLAG, { isLoadingUsdtAddress: false });
      commit(types.default.SET_USDT_DATA, response.data);
      return response.data;
    } catch (error) {
      commit(types.default.SET_PAYMENT_UI_FLAG, { isLoadingUsdtAddress: false });
      return throwErrorMessage(error);
    }
  },
  async cancelPayment({ dispatch }, payment_id) {
    try {
      const response = await PaymentAPI.delete(payment_id);
      await dispatch('fetchSubscriptionAndPayment');
      return response.data;
    } catch (error) {
      return throwErrorMessage(error);
    }
  },
  createOrder: async function createOrder({ commit }, paymentObj) {
    commit(types.default.SET_PAYMENT_UI_FLAG, { paying: true });
    try {
      const response = await PaymentAPI.create(paymentObj);
      commit(types.default.ADD_PAYMENT, response.data.payment);
      return response.data;
    } catch (error) {
      return throwErrorMessage(error);
    }
  },
  fetchConvCount: async ({ commit }) => {
    const response = await ConversationAPI.getCount();
    commit(types.default.SET_CONVCOUNT, response.data.count);
  }
};

const mutations = {
  [types.default.SET_PLANLIST](_state, data) {
    _state.records = data;
  },
  [types.default.SET_SUBSCRIPTIONS](_state, data) {
    _state.subscriptions = data;
  },
  [types.default.SET_SUBSCRIPTION](_state, data) {
    _state.subscriptions = [data];
  },
  [types.default.SET_PAYMENTS](_state, data) {
    _state.payments = data;
  },
  [types.default.ADD_PAYMENT](_state, data) {
    _state.payments = [data, ..._state.payments];
  },
  [types.default.SET_PAYMENT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data
    };
  },
  [types.default.SET_CONVCOUNT](_state, count) {
    _state.convCount = count;
  },
  [types.default.SET_USDT_DATA](_state, data) {
    _state.usdtData = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
