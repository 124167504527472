<template>
  <div
    class="bg-white dark:bg-slate-800 border border-solid
           border-slate-75 dark:border-slate-700/50 rounded-sm mb-4 p-4"
  >
    <!-- Display payment details -->
    <div>
      <p>
        {{ $t('PAYMENT_MGMT.WAITING_PAYMENT.UNPAID') }}
        <woot-button class="mr-2" variant="link" size="small" @click="handleCancelPayment">
          {{ $t('PAYMENT_MGMT.WAITING_PAYMENT.CANCEL') }}
        </woot-button>
      </p>
      <p>
        {{ $t('PAYMENT_MGMT.WAITING_PAYMENT.CREATED_AT') }}:
        {{ latestPayment.created_at.split('T')[0] }}
      </p>
      <p>
        {{ paymentPlanName(latestPayment.plan_id) }}: {{ latestPayment.currency }}
        {{ latestPayment.total_amount }} {{ txt }}
      </p>
    </div>

    <!-- Three buttons for different payment methods -->
    <div class="flex mt-4">
      <!-- <woot-button class="mr-2" color-scheme="success" @click="handlePayment('card')">
        {{ $t('PAYMENT_MGMT.PAYMENT_METHODS.CARD') }}
      </woot-button>
      <woot-button class="mr-2" color-scheme="success" @click="handlePayment('alipay')">
        {{ $t('PAYMENT_MGMT.PAYMENT_METHODS.ALIPAY') }}
      </woot-button> -->
      <woot-button class="mr-2" color-scheme="success" @click="handleCryptoPayment()">
        {{ $t('PAYMENT_MGMT.PAY_WITH.CRYPTO') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    latestPayment: {
      type: Object,
      default: () => ({})
    },
    handleCryptoPayment: {
      type: Function,
      default: () => {}
    },
    paymentPlanName: {
      type: Function,
      default: () => ''
    }
  },
  computed: {
    txt() {
      if (this.latestPayment.invoice_type === 'upgrade') {
        return '';
      }
      return this.$t('PAYMENT_MGMT.WAITING_PAYMENT.PER_YEAR');
    },
  },
  methods: {
    async handleCancelPayment() {
      try {
        await this.$store.dispatch('payment/cancelPayment', this.latestPayment.id);
      } catch (error) {
        const errorMessage = error?.message || '';
        this.showAlert(errorMessage);
      }
    },
  }
};
</script>
