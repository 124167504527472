import types from '../../mutation-types';
import NotificationsAPI from '../../../api/notifications';

export const actions = {
  get: async ({ commit }, { page = 1 } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get(page);
      commit(types.CLEAR_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    }
  },
  unReadCount: async ({ commit } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnreadCount();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, data);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    }
  },
  unRead: async ({ commit } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnread();
      commit(types.SET_NOTIFICATIONS_UNREAD, data.data);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    }
  },
  readBatch: async (
    { commit },
    { primaryActorType, primaryActorIds }
  ) => {
    try {
      await NotificationsAPI.readBatch(primaryActorType, primaryActorIds);
      primaryActorIds.forEach(id => {
        commit(types.UPDATE_NOTIFICATION, {
          primaryActorType: primaryActorType,
          primaryActorId: id
        });
      });
    } catch (error) {
      // ignore
      // throw new Error(error);
    }
  },
  read: async (
    { commit },
    { primaryActorType, primaryActorId }
  ) => {
    try {
      await NotificationsAPI.read(primaryActorType, primaryActorId);
      commit(types.UPDATE_NOTIFICATION, {
        primaryActorType: primaryActorType,
        primaryActorId: primaryActorId
      });
    } catch (error) {
      // ignore
      // throw new Error(error);
    }
  },
  tryRead: async ({ dispatch, state }, { primaryActorType, primaryActorId }) => {
    const exists = Object.values(state.unread).some(notification => notification.primary_actor_type === primaryActorType
      && notification.primary_actor_id === primaryActorId);
    if (exists) {
      const { unreadCount } = state.meta;
      await dispatch('read', { primaryActorType, primaryActorId, unreadCount });
    }
  },
  readAll: async ({ commit }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.readAll();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, 0);
      commit(types.UPDATE_ALL_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      throw new Error(error);
    }
  },

  addNotification({ commit }, data) {
    commit(types.ADD_NOTIFICATION, data);
  },
};
