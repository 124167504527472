<template>
  <div>
    <router-link
      :to="addAccountScoping('settings/canned-response/new')"
      class="button success button--fixed-top flex gap-1"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
      </span>
    </router-link>
    <woot-button
      v-if="isAdmin"
      color-scheme="info"
      icon="upload"
      class="clear fixed top-2 ltr:right-40 rtl:left-40"
      @click="toggleImport"
    >
      {{ $t('CANNED_MGMT.IMPORT.BUTTON_LABEL') }}
    </woot-button>
  </div>
</template>
<script>
import accountMixin from 'dashboard/mixins/account.js';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  mixins: [accountMixin, adminMixin],
  methods: {
    toggleImport() {
      this.$emit('on-toggle-import');
    },
  }
};
</script>
