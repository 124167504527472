/* global axios */
import ApiClient from './ApiClient';

class BlacklistIpsAPI extends ApiClient {
  constructor() {
    super('blacklist_ips', { accountScoped: true });
  }

  get(page) {
    let requestURL = `${this.url}?page=${page}`;
    return axios.get(requestURL);
  }

  destroyBlacklistIps(ips) {
    return axios.delete(`${this.url}`, {
      data: {
        ips: ips
      }
    });
  }
}

export default new BlacklistIpsAPI();
