import Vue from 'vue';
import types from '../mutation-types';
import RobotQasAPI from '../../api/robotQas';

const state = {
  meta: {
    count: 0,
    currentPage: 1,
    pageSize: 15,
  },
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getRobotQas($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getMeta($state) {
    return $state.meta;
  },
};

export const actions = {
  fetchRobotQas: async ({ commit }, { page }) => {
    commit(types.SET_ROBOTQAS_UI_FLAG, { isFetching: true });
    try {
      const { data: { payload, meta } } = await RobotQasAPI.get(page);
      commit(types.CLEAR_ROBOTQAS);
      commit(types.SET_ROBOTQAS, payload);
      commit(types.SET_ROBOTQAS_META, meta);
      commit(types.SET_ROBOTQAS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ROBOTQAS_UI_FLAG, { isFetching: false });
    }
  },

  createRobotQa: async ({ commit }, { question, answer }) => {
    commit(types.SET_ROBOTQAS_UI_FLAG, { isCreating: true });
    try {
      const response = await RobotQasAPI.create({ question, answer });
      commit(types.ADD_ROBOTQA, response.data);
      commit(types.SET_ROBOTQAS_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_ROBOTQAS_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },

  deleteRobotQa: async ({ state: $state, commit, dispatch }, id) => {
    commit(types.SET_ROBOTQAS_UI_FLAG, { isDeleting: true });
    try {
      await RobotQasAPI.delete(id);
      commit(types.REMOVE_ROBOTQA, id);

      // 如果删除后当前页没有数据并且不在第一页，则加载前一页
      if ($state.records.length === 0 && $state.meta.currentPage > 1) {
        dispatch('fetchRobotQas', { page: $state.meta.currentPage - 1 });
      } else {
        // 否则如果不是最后一页，则重新加载当前页
        const totalPages = Math.ceil($state.meta.count / $state.meta.pageSize);
        if ($state.meta.currentPage < totalPages) {
          dispatch('fetchRobotQas', { page: $state.meta.currentPage });
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ROBOTQAS_UI_FLAG, { isDeleting: false });
    }
  },

};

export const mutations = {
  [types.SET_ROBOTQAS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_ROBOTQAS]: ($state, data) => {
    $state.records = data;
  },

  [types.ADD_ROBOTQA]: ($state, data) => {
    Vue.set($state.meta, 'count', state.meta.count + 1);
    if (state.records.length < state.meta.pageSize) {
      $state.records.push(data);
    }
  },

  [types.SET_ROBOTQAS_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },

  [types.CLEAR_ROBOTQAS]: $state => {
    Vue.set($state, 'records', []);
    Vue.set($state.meta, 'count', 0);
    Vue.set($state.meta, 'currentPage', 1);
  },

  [types.REMOVE_ROBOTQA]: ($state, id) => {
    $state.records = $state.records.filter(record => record.id !== id);
    Vue.set($state.meta, 'count', state.meta.count - 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
