import SettingsContent from '../Wrapper';
import PaymentHome from './Index';
import PaymentUpgrade from './PaymentUpgrade';
import PaymentUSDT from './PaymentUSDT';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/payment'),
      component: SettingsContent,
      props: {
        headerTitle: 'PAYMENT_MGMT.HEADER',
        icon: 'credit-card-person',
        showNewButton: false
      },
      children: [
        {
          path: '',
          name: 'payment_wrapper',
          redirect: 'list'
        },
        {
          path: 'list',
          name: 'payment_list',
          roles: ['administrator', 'agent'],
          component: PaymentHome
        },
        {
          path: 'upgrade',
          name: 'payment_upgrade',
          roles: ['administrator', 'agent'],
          component: PaymentUpgrade,
          meta: {
            headerTitle: 'PAYMENT_MGMT.UPGRADE_HEADER'
          }
        },
        {
          path: 'usdt',
          name: 'payment_usdt',
          roles: ['administrator', 'agent'],
          component: PaymentUSDT
        }
      ]
    }
  ]
};
