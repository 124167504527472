<template>
  <div class="h-full overflow-auto flex flex-col">
    <woot-modal-header :header-title="pageTitle" />
    <form class="flex flex-col w-2/3 px-8 pt-2" @submit.prevent="editCannedResponse()">
      <div>
        <label :class="{ error: $v.shortCode.$error }">
          {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
          <input
            v-model.trim="shortCode"
            type="text"
            :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
            @input="$v.shortCode.$touch"
          />
        </label>
      </div>
      <div>
        <label :class="{ error: $v.content.$error }">
          {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
        </label>
        <div class="editor-wrap">
          <woot-message-editor
            v-model="content"
            class="message-editor"
            :class="{ editor_warning: $v.content.$error }"
            :enable-variables="true"
            :enable-canned-responses="false"
            :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
            @blur="$v.content.$touch"
          />
        </div>
      </div>
      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-submit-button
          :disabled="
            $v.content.$invalid ||
              $v.shortCode.$invalid ||
              editCanned.showLoading
          "
          :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
          :loading="editCanned.showLoading"
        />
        <button class="button clear" @click.prevent="onClose">
          {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
      },
      shortCode: '',
      content: '',
      show: true,
      id: '',
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.shortCode}`;
    },
    cannedId() {
      return Number(this.$route.params.cannedId);
    },
  },
  watch: {
    $route: {
      async handler() {
        if (this.$route.params.cannedId) {
          await this.fetchCannedResponse();
        }
      },
      immediate: true,
    },
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    onClose() {
      this.resetForm();
      this.$router.push({ name: 'canned_list' });
    },
    async fetchCannedResponse() {
      let canned = this.$store.getters.getCannedResponseById(this.cannedId);
      if (!canned) {
        await this.$store.dispatch('getSingleCannedResponse', this.cannedId);
        canned = this.$store.getters.getCannedResponseById(this.cannedId);
      }
      this.id = canned.id;
      this.shortCode = canned.short_code;
      this.content = canned.content;
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          content: this.content,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          this.resetForm();
          setTimeout(() => {
            this.$router.push({ name: 'canned_list' });
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage = error?.message || this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-woot-style {
      @apply min-h-[22.5rem];

      p {
        @apply text-base;
      }
    }
  }
}
</style>
