<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-loading-state
      v-if="isLoading || isFetching"
      :message="$t('PAYMENT_MGMT.LOADING')"
    />
    <div v-else-if="!!currentPlan">
      <div class="flex flex-col">
        <plan-list
          type="upgrade"
          :plan-list="planList"
          :current-info="currentInfo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanList from './PlanList';

export default {
  components: {
    PlanList,
  },
  data() {
    return {
      isLoading: true
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.currentPlan) {
        vm.$router.push({
          name: 'payment_list',
          params: { accountId: to.params.accountId },
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      planList: 'payment/getPlanList',
      subscriptions: 'payment/getSubscriptions',
      isFetching: 'payment/isFetching',
    }),
    currentPlan() {
      if (this.subscriptions.length > 0) {
        const planId = this.subscriptions[0].plan_id;
        return this.planList.find(plan => plan.id === planId) || null;
      }
      return null;
    },
    currentInfo() {
      return {
        plan: this.currentPlan,
        planName: this.currentPlan.name,
        userCount: this.subscriptions[0].user_count,
        endAt: this.subscriptions[0].end_at
      };
    }
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch('payment/fetchPlanList'),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
