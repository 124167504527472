<template>
  <div>
    <div v-if="isATwilioChannel" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
        :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
      >
        <woot-code :script="inbox.callback_webhook_url" lang="html" />
      </settings-section>
    </div>
    <div v-else-if="isALineChannel" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE')"
        :sub-title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE')"
      >
        <woot-code :script="inbox.callback_webhook_url" lang="html" />
      </settings-section>
    </div>
    <div v-else-if="isAWebWidgetInbox">
      <div class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING')"
          :sub-title="subTitleText"
        >
          <woot-code
            :script="inbox.web_widget_script"
            lang="html"
            :codepen-title="`${inbox.name} - Widget Test`"
            :enable-code-pen="true"
          />
        </settings-section>

        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
        >
          <woot-code :script="inbox.hmac_token" />
          <template #subTitle>
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION') }}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.chatwoot.com/docs/product/channels/live-chat/sdk/identity-validation/"
            >
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_LINK_TO_DOCS') }}
            </a>
          </template>
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')"
        >
          <div class="enter-to-send--checkbox">
            <input
              id="hmacMandatory"
              v-model="hmacMandatory"
              type="checkbox"
              @change="handleHmacFlag"
            />
            <label for="hmacMandatory">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
            </label>
          </div>
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.CONV_LINK')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.CONV_LINK_SUBTEXT')"
        >
          <div>
            <input v-model="linkUrl" type="text" class="widget-link--input font-mono" readonly />
            <vue-qrcode :value="linkUrl" />
          </div>
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.DOWNLOAD_HTML_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.DOWNLOAD_HTML_SUBTEXT')"
        >
          <woot-button color-scheme="success" icon="arrow-download" @click="downloadStandalonePage">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.DOWNLOAD_HTML_LINK_LABEL') }}
          </woot-button>
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_SUBTEXT')"
        >
          <div>
            <label>
              {{ hostBindExplanation }}
            </label>
            <label v-if="inbox.standalone_page_hosts.length" class="my-2">
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_HOSTS_PRE') }} <span class="text-green-500">{{ standalone_page_hosts_str }}</span> {{ $t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_HOSTS_POST') }}
            </label>
            <input
              v-model="customHostname"
              type="text"
              :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_INPUT_PLACEHOLDER')"
            />
            <woot-button color-scheme="primary" @click="confirmHostBind">
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_CONFIRM_BUTTON_LABEL') }}
            </woot-button>
          </div>
        </settings-section>
      </div>
    </div>
    <div v-else-if="isAPIInbox" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
      >
        <woot-code :script="inbox.inbox_identifier" />
      </settings-section>

      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
      >
        <woot-code :script="inbox.hmac_token" />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')"
      >
        <div class="enter-to-send--checkbox">
          <input
            id="hmacMandatory"
            v-model="hmacMandatory"
            type="checkbox"
            @change="handleHmacFlag"
          />
          <label for="hmacMandatory">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
          </label>
        </div>
      </settings-section>
    </div>
    <div v-else-if="isAnEmailChannel">
      <div class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT')"
        >
          <woot-code :script="inbox.forward_to_email" />
        </settings-section>
      </div>
      <imap-settings :inbox="inbox" />
      <smtp-settings v-if="inbox.imap_enabled" :inbox="inbox" />
      <microsoft-reauthorize
        v-if="inbox.microsoft_reauthorization"
        :inbox="inbox"
      />
    </div>
    <div v-else-if="isAWhatsAppChannel && !isATwilioChannel">
      <div v-if="inbox.provider_config" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_SUBHEADER')"
        >
          <woot-code :script="inbox.provider_config.webhook_verify_token" />
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_SUBHEADER')"
        >
          <woot-code :script="inbox.provider_config.api_key" />
        </settings-section>
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_TITLE')"
          :sub-title="
            $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_SUBHEADER')
          "
        >
          <div class="whatsapp-settings--content">
            <woot-input
              v-model.trim="whatsAppInboxAPIKey"
              type="text"
              class="input"
              :placeholder="
                $t(
                  'INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_PLACEHOLDER'
                )
              "
            />
            <woot-button
              :disabled="$v.whatsAppInboxAPIKey.$invalid"
              @click="updateWhatsAppInboxAPIKey"
            >
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
            </woot-button>
          </div>
        </settings-section>
      </div>
    </div>
    <woot-need-payment-modal ref="paymentModal" />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';
import ImapSettings from '../ImapSettings';
import SmtpSettings from '../SmtpSettings';
import MicrosoftReauthorize from '../channels/microsoft/Reauthorize';
import { required } from 'vuelidate/lib/validators';
import { buildWidgetLink } from '../../../../../helper/inbox';
import VueQrcode from 'qrcode.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SettingsSection,
    ImapSettings,
    SmtpSettings,
    MicrosoftReauthorize,
    VueQrcode
  },
  mixins: [inboxMixin, alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hmacMandatory: false,
      whatsAppInboxAPIKey: '',
      customHostname: ''
    };
  },
  validations: {
    whatsAppInboxAPIKey: { required }
  },
  mounted() {
    this.setDefaults();
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonPlan: 'accounts/isFeatureEnabledonPlan',
      getPlanId: 'accounts/getPlanId',
    }),
    featureExists() {
      return this.isFeatureEnabledonPlan('custom_domain_binding');
    },
    linkUrl() {
      return buildWidgetLink(this.inbox.rand_sub_domain, this.getPlanId);
    },
    standalone_page_hosts_str() {
      return this.inbox.standalone_page_hosts.join(',');
    },
    subTitleText() {
      return this.$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD', { mainUrl: window.chatwootConfig.mainURL });
    },
    hostBindExplanation() {
      const parsedUrl = new URL(window.chatwootConfig.hostURL);
      return this.$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_EXPLANATION', { appHost: parsedUrl.hostname });
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    }
  },
  methods: {
    setDefaults() {
      this.hmacMandatory = this.inbox.hmac_mandatory || false;
    },
    handleHmacFlag() {
      this.updateInbox();
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            hmac_mandatory: this.hmacMandatory
          }
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateWhatsAppInboxAPIKey() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {}
        };

        payload.channel.provider_config = {
          ...this.inbox.provider_config,
          api_key: this.whatsAppInboxAPIKey
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    downloadStandalonePage() {
      try {
        this.$store.dispatch('inboxes/getStandalonePage', {
          inboxId: this.inbox.id,
          fileName: 'standalone_page.html'
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.SETTINGS_POPUP.DOWNLOAD_HTML_FAILED'));
      }
    },
    async confirmHostBind() {
      if (this.featureExists) {
        try {
          await this.$store.dispatch('inboxes/confirmHostBind', {
            inboxId: this.inbox.id,
            host: this.customHostname
          });
          this.showAlert(this.$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_SUCCESS'));
        } catch (error) {
          let errorMsg = error.message;
          if (errorMsg) {
            if (errorMsg === 'Host is not a valid hostname') {
              errorMsg = this.$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_ERROR_INVALID_HOSTNAME');
            }
            if (errorMsg === 'Improper hostname') {
              errorMsg = this.$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_ERROR_IMPROPER_HOSTNAME');
            }
          } else {
            errorMsg = this.$t('INBOX_MGMT.SETTINGS_POPUP.HOST_BIND_ERROR');
          }
          this.showAlert(errorMsg);
        }
      } else {
        this.$refs.paymentModal.showDialog();
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.widget-link--input {
  @apply rounded-md font-semibold border-woot-600 cursor-default bg-white
}

.whatsapp-settings--content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--space-small);

  .input {
    flex: 1;
    margin-right: var(--space-small);
    ::v-deep input {
      margin-bottom: 0;
    }
  }
}

.hmac-link-to-docs {
  margin-top: var(--space-small);
}
</style>
