<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] h-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :header-content="
        useInstallationName(
          $t('INBOX_MGMT.ADD.AUTH.DESC'),
          globalConfig.installationName
        )
      "
    />
    <div class="mt-6 mx-0 flex flex-wrap">
      <channel-item
        v-for="channel in channelList"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth"
      />
    </div>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { generateRandomSubDomain } from 'dashboard/helper/inbox';

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  mixins: [globalConfigMixin, alertMixin],
  data() {
    return {
      enabledFeatures: {},
    };
  },
  computed: {
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    channelList() {
      const { apiChannelName, apiChannelThumbnail } = this.globalConfig;
      let list = [
        { key: 'website', name: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.NAME') },
        // { key: 'facebook', name: 'Messenger' },
        { key: 'whatsapp', name: 'WhatsApp' },
        { key: 'sms', name: 'SMS' },
        { key: 'email', name: 'Email' },
        {
          key: 'api',
          name: apiChannelName || 'API',
          thumbnail: apiChannelThumbnail,
        },
        { key: 'telegram', name: 'TelegramBot' },
        { key: 'line', name: 'Line' },
      ];
      if (window.chatwootConfig.productName === 'Voxsig') {
        list.push({ key: 'telegram_mt', name: 'Telegram' });
      }
      return list;
    },
    sanitizeName() {
      const account_name = this.account.name;
      let names = this.inboxes.map(x => x.name);
      // Remove illegal characters
      let sanitized = account_name.replace(/[<>/\\@]/g, '');
      let final_name = sanitized;

      // Initialize a counter
      let counter = 1;

      // Keep checking and incrementing the counter until a unique name is found
      while (names.includes(final_name)) {
        counter += 1;
        final_name = sanitized + counter;
      }

      return final_name;
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
    }),
  },
  mounted() {
    this.initializeEnabledFeatures();
  },
  methods: {
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);
      this.enabledFeatures = this.account.features;
    },
    initChannelAuth(channel) {
      if (channel === 'website') {
        this.createWebsiteChannel();
        return;
      }
      // 其他渠道
      const params = {
        page: 'new',
        sub_page: channel,
      };
      router.push({ name: 'settings_inboxes_page_channel', params });
    },
    async createWebsiteChannel() {
      try {
        const website = await this.$store.dispatch(
          'inboxes/createWebsiteChannel',
          {
            name: this.sanitizeName,
            greeting_enabled: true,
            greeting_message: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_GREETING'),
            channel: {
              type: 'web_widget',
              website_url: '',
              widget_color: '#009CE0',
              welcome_title: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_WELCOME_TITLE'),
              welcome_tagline: this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DEFAULT_WELCOME_TAGLINE'),
              rand_sub_domain: generateRandomSubDomain(),
            },
          }
        );
        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: website.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message
            || this.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
<style scoped>
.height-auto {
  height: auto;
}

.channel-list {
  margin-top: var(--space-medium);
}
</style>
