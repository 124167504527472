<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('ROBOTQAS_MGMT.ADD.TITLE')"
        :header-content="$t('ROBOTQAS_MGMT.ADD.DESC')"
      />
      <form class="flex flex-col w-full" @submit.prevent="addQA()">
        <div class="w-full">
          <label :class="{ error: $v.question.$error }">
            {{ $t('ROBOTQAS_MGMT.ADD.FORM.QUESTION.LABEL') }}
            <input
              v-model.trim="question"
              type="text"
              :placeholder="$t('ROBOTQAS_MGMT.ADD.FORM.QUESTION.PLACEHOLDER')"
              @input="$v.question.$touch"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.answer.$error }">
            {{ $t('ROBOTQAS_MGMT.ADD.FORM.ANSWER.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="answer"
              class="message-editor"
              :class="{ editor_warning: $v.answer.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('ROBOTQAS_MGMT.ADD.FORM.ANSWER.PLACEHOLDER')"
              @blur="$v.answer.$touch"
            />
          </div>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-submit-button
            :disabled="
              $v.answer.$invalid ||
                $v.question.$invalid ||
                showLoading
            "
            :button-text="$t('ROBOTQAS_MGMT.ADD.FORM.SUBMIT')"
            :loading="showLoading"
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('ROBOTQAS_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      question: '',
      answer: '',
      showLoading: false,
      message: '',
      show: true,
    };
  },
  validations: {
    question: {
      required,
      minLength: minLength(1),
    },
    answer: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    resetForm() {
      this.question = '';
      this.answer = '';
      this.$v.question.$reset();
      this.$v.answer.$reset();
    },
    addQA() {
      // Show loading on button
      this.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('robotQas/createRobotQa', {
          question: this.question,
          answer: this.answer,
        })
        .then(() => {
          // Reset Form, Show success message
          this.showLoading = false;
          this.showAlert(this.$t('ROBOTQAS_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose();
        })
        .catch(error => {
          this.showLoading = false;
          const errorMessage = error?.message || this.$t('ROBOTQAS_MGMT.ADD.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    @apply hidden;
  }

  .ProseMirror-woot-style {
    @apply min-h-[12.5rem];

    p {
      @apply text-base;
    }
  }
}
</style>
