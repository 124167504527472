<template>
  <div class="overflow-auto" :class="compact ? 'py-0 px-0' : 'py-3 px-4'">
    <div class="items-center flex justify-between mb-1.5">
      <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
        referrer:
      </span>
      <a :href="pageview.referrer_url" class="text-blue-600 hover:text-blue-800" target="_blank">
        {{ pageview.referrer_url }}
      </a>
    </div>
    <div class="items-center flex justify-between mb-1.5">
      <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
        url:
      </span>
      <a :href="pageview.visit_url" class="text-blue-600 hover:text-blue-800" target="_blank">
        {{ pageview.visit_url }}
      </a>
    </div>
    <div class="items-center flex justify-between mb-1.5">
      <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
        created at:
      </span>
      {{ created_at }}
    </div>
    <div class="items-center flex justify-between mb-1.5">
      <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
        duration:
      </span>
      {{ duration }}s
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    pageview: { type: Object, required: true },
    compact: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      getLocale: 'accounts/getLocale',
    }),
    duration() {
      return this.pageview.left_at - this.pageview.created_at;
    },
    referer() {
      return this.conversationAttributes.referer;
    },
    created_at() {
      const date = new Date(this.pageview.created_at * 1000);
      const locale = this.getLocale.replace('_', '-');
      return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short'
      }).format(date);
    },
  },
};
</script>
