/* global axios */

import ApiClient from './ApiClient';

class Payment extends ApiClient {
  constructor() {
    super('payments', { accountScoped: true });
  }

  getPlanList() {
    return axios.get(`${this.baseUrl()}/plans`);
  }

  getSubscription() {
    return axios.get(`${this.baseUrl()}/subscriptions`);
  }

  getPayments() {
    return axios.get(`${this.baseUrl()}/payments`);
  }

  makePayment(data) {
    return axios.post(`${this.url}/make_payment`, data);
  }
}

export default new Payment();
