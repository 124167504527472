<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="flex flex-row gap-4 pl-2">
      <div class="w-[60%]">
        <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
          <woot-tabs-item
            v-for="tab in tabs"
            :key="tab.key"
            :name="tab.name"
            :show-badge="false"
          />
        </woot-tabs>

        <div class="w-full">
          <p
            v-if="!uiFlags.isFetching && !getBlacklist.length"
            class="mt-12 flex items-center justify-center"
          >
            {{ $t('BLACKLIST_MGMT.LIST.EMPTY_RESULT.404') }}
          </p>
          <woot-loading-state
            v-if="uiFlags.isFetching"
            :message="$t('BLACKLIST_MGMT.LOADING')"
          />
          <table
            v-if="!uiFlags.isFetching && getBlacklist.length"
            class="w-full mt-2 table-fixed woot-table"
          >
            <thead>
              <th
                v-for="tableHeader in tabHeaders(selectedTabIndex)"
                :key="tableHeader"
                class="pl-0 max-w-[6.25rem] min-w-[5rem]"
              >
                {{ tableHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="item in getBlacklist" :key="item.id">
                <td
                  class="pl-0 max-w-[5.25rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {{ item.display_name }}
                </td>
                <td
                  class="blacklist-key pl-0 max-w-[6.25rem] min-w-[5rem] overflow-hidden
                  whitespace-nowrap text-ellipsis"
                  :title="tab2Text(item)"
                >
                  {{ tab2Text(item) }}
                </td>
                <td
                  class="pl-0 max-w-[6.25rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {{ item.user_name }}
                </td>
                <td
                  class="pl-0 max-w-[10rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {{ item.created_at }}
                </td>
                <td class="button-wrapper">
                  <woot-button
                    v-tooltip.top="$t('BLACKLIST_MGMT.LIST.BUTTONS.DELETE')"
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    icon="dismiss-circle"
                    class-names="grey-btn"
                    @click="openDelete(item)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table-footer
            v-if="!uiFlags.isFetching && getBlacklist.length"
            :current-page="meta.currentPage"
            :total-count="meta.count"
            :page-size="15"
            @page-change="onPageChange"
          />
        </div>
      </div>
      <div class="w-[34%]">
        <span v-dompurify-html="$t('BLACKLIST_MGMT.SIDEBAR_TXT')" />
      </div>
      <woot-confirm-delete-modal
        v-if="showDeletePopup"
        :show.sync="showDeletePopup"
        :title="confirmDeleteTitle"
        :message="$t('BLACKLIST_MGMT.DELETE.CONFIRM.MESSAGE')"
        :confirm-text="deleteConfirmText"
        :reject-text="deleteRejectText"
        :confirm-value="selectedBlacklist.display_name"
        :confirm-place-holder-text="confirmPlaceHolderText"
        @on-confirm="confirmDeletion"
        @on-close="closeDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { getCountryFlag } from 'dashboard/helper/flag';
import TableFooter from 'dashboard/components/widgets/TableFooter';

export default {
  components: {
    TableFooter
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedTabIndex: 0,
      showDeletePopup: false,
      selectedBlacklist: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'blacklist/getUIFlags',
      getBlacklist: 'blacklist/getBlacklist',
      meta: 'blacklist/getMeta',
    }),
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('BLACKLIST_MGMT.TABS.CONTACT'),
        },
        {
          key: 1,
          name: this.$t('BLACKLIST_MGMT.TABS.IP'),
        },
      ];
    },
    deleteConfirmText() {
      return `${this.$t('BLACKLIST_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedBlacklist.display_name
      }`;
    },
    deleteRejectText() {
      return this.$t('BLACKLIST_MGMT.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('BLACKLIST_MGMT.DELETE.CONFIRM.TITLE', {
        blacklistName: this.selectedBlacklist.display_name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('BLACKLIST_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        blacklistName: this.selectedBlacklist.display_name,
      })}`;
    },
  },
  mounted() {
    this.fetchBlacklist(this.selectedTabIndex, 1);
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
      this.fetchBlacklist(index, 1);
    },
    tabHeaders(index) {
      if (index === 0) {
        return this.$t('BLACKLIST_MGMT.LIST.TABLE_HEADER_CONTACT');
      }
      return this.$t('BLACKLIST_MGMT.LIST.TABLE_HEADER_IP');
    },
    fetchBlacklist(index, page) {
      if (index === 0) {
        this.$store.dispatch('blacklist/getBlacklistContacts', { page });
      } else {
        this.$store.dispatch('blacklist/getBlacklistIps', { page });
      }
    },
    async deleteBlacklist(item) {
      try {
        if (item.type === 'contact') {
          await this.$store.dispatch('blacklist/deleteBlacklistContacts', [item.contact_id]);
        } else if (item.type === 'ip') {
          await this.$store.dispatch('blacklist/deleteBlacklistIps', [item.ip]);
        }
        this.showAlert(this.$t('BLACKLIST_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        if (this.meta.count > 0 && this.meta.currentPage > 1) {
          this.fetchBlacklist(this.selectedTabIndex, this.meta.currentPage - 1);
        }
      } catch (error) {
        const errorMessage = error?.response?.message || this.$t('BLACKLIST_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    confirmDeletion() {
      this.deleteBlacklist(this.selectedBlacklist);
      this.closeDelete();
    },
    openDelete(value) {
      this.deleteBlacklist(value);
      // 暂时不需要二次确认了
      // this.showDeletePopup = true;
      // this.selectedBlacklist = value;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedBlacklist = {};
    },
    tab2Text(item) {
      if (this.selectedTabIndex === 0) {
        return item.contact_id;
      }
      return this.location(item.city, item.country, item.country_code);
    },
    location(city, country, countryCode) {
      const cityAndCountry = [city, country].filter(item => !!item).join(',');
      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry}${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    onPageChange(page) {
      this.fetchBlacklist(this.selectedTabIndex, page);
    },
  },
};
</script>

<style lang="scss" scoped>
.blacklist-key {
  font-family: monospace;
}

::v-deep {
  .tabs--container {
    .tabs {
      @apply p-0;
    }
  }
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}
</style>
