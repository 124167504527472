<template>
  <div class="h-full overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('CANNED_MGMT.ADD.TITLE')"
      :header-content="$t('CANNED_MGMT.ADD.DESC')"
    />
    <form class="flex flex-col w-2/3 px-8 pt-2" @submit.prevent="addCannedResponse()">
      <div>
        <label :class="{ error: $v.shortCode.$error }">
          {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
          <input
            v-model.trim="shortCode"
            type="text"
            :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
            @input="$v.shortCode.$touch"
          />
        </label>
      </div>

      <div>
        <label :class="{ error: $v.content.$error }">
          {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
        </label>
        <div class="editor-wrap">
          <woot-message-editor
            v-model="content"
            class="message-editor"
            :class="{ editor_warning: $v.content.$error }"
            :enable-variables="true"
            :enable-canned-responses="false"
            :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
            @blur="$v.content.$touch"
          />
        </div>
      </div>
      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-submit-button
          :disabled="
            $v.content.$invalid ||
              $v.shortCode.$invalid ||
              addCanned.showLoading
          "
          :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
          :loading="addCanned.showLoading"
        />
        <button class="button clear" @click.prevent="onClose">
          {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shortCode: '',
      content: this.responseContent || '',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    onClose() {
      this.resetForm();
      this.$router.push({ name: 'canned_list' });
    },
    addCannedResponse() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          content: this.content,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.$router.push({ name: 'canned_list' });
        })
        .catch(error => {
          this.addCanned.showLoading = false;
          let errorMessage = error?.message || this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');
          if (errorMessage === 'Short code has already been taken') {
            errorMessage = this.$t('CANNED_MGMT.ADD.API.DUPLICATE_MESSAGE');
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-woot-style {
      @apply min-h-[22.5rem];

      p {
        @apply text-base;
      }
    }
  }
}
</style>
