import SettingsContent from '../Wrapper';
import BlacklistHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/blacklist'),
      component: SettingsContent,
      props: {
        headerTitle: 'BLACKLIST_MGMT.HEADER',
        icon: 'warning',
        showNewButton: false
      },
      children: [
        {
          path: '',
          name: 'blacklist_wrapper',
          redirect: 'list'
        },
        {
          path: 'list',
          name: 'blacklist_list',
          roles: ['administrator', 'agent'],
          component: BlacklistHome
        }
      ]
    }
  ]
};
