import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_NOTIFICATIONS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.CLEAR_NOTIFICATIONS]: $state => {
    Vue.set($state, 'records', {});
  },
  [types.SET_NOTIFICATIONS_META]: ($state, data) => {
    const {
      count,
      current_page: currentPage,
      unread_count: unreadCount,
    } = data;

    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
    Vue.set($state.meta, 'unreadCount', unreadCount);
  },
  [types.SET_NOTIFICATIONS_UNREAD_COUNT]: ($state, count) => {
    Vue.set($state.meta, 'unreadCount', count < 0 ? 0 : count);
  },
  [types.SET_NOTIFICATIONS_UNREAD]: ($state, data) => {
    const { unread_count: unreadCount } = data.meta;
    Vue.set($state.meta, 'unreadCount', unreadCount < 0 ? 0 : unreadCount);
    Vue.set($state, 'unread', {});
    data.payload.forEach(notification => {
      Vue.set($state.unread, notification.id, notification);
    });
  },
  [types.SET_NOTIFICATIONS]: ($state, data) => {
    data.forEach(notification => {
      Vue.set($state.records, notification.id, {
        ...($state.records[notification.id] || {}),
        ...notification,
      });
    });
  },
  [types.UPDATE_NOTIFICATION]: ($state, payload) => {
    Object.values($state.unread).forEach(item => {
      if (item.primary_actor_type === payload.primaryActorType
          && item.primary_actor_id === payload.primaryActorId) {
        if ($state.records[item.id] !== undefined) {
          Vue.set($state.records[item.id], 'read_at', true);
        }
        Vue.delete($state.unread, item.id);
        Vue.set($state.meta, 'unreadCount', $state.meta.unreadCount - 1);
      }
    });
  },
  [types.UPDATE_ALL_NOTIFICATIONS]: $state => {
    Object.values($state.records).forEach(item => {
      Vue.set($state.records[item.id], 'read_at', true);
    });
    Vue.set($state, 'unread', {});
  },

  [types.ADD_NOTIFICATION]($state, data) {
    const { notification, unread_count: unreadCount, count } = data;
    Vue.set($state.records, notification.id, {
      ...($state.records[notification.id] || {}),
      ...notification,
    });
    Vue.set($state.meta, 'unreadCount', unreadCount);
    Vue.set($state.meta, 'count', count);

    if (!notification.read_at) {
      Vue.set($state.unread, notification.id, {
        id: notification.id,
        primary_actor_type: notification.primary_actor_type,
        primary_actor_id: notification.primary_actor_id,
      });
    }
  },

};
