<template>
  <div
    class="bg-white dark:bg-slate-800 border border-solid border-slate-75
               dark:border-slate-700/50 rounded-sm mb-4 p-4"
  >
    {{ $t('PAYMENT_MGMT.SUBSCRIPTION.TITLE') }} {{ planName }} <woot-button v-if="planName != 'Enterprise'" class="ml-3 h-8" color-scheme="success" @click="handleUpgrade">{{ $t('PAYMENT_MGMT.SUBSCRIPTION.UPGRADE_TXT') }}</woot-button> <br />
    {{ currency }} {{ unitAmount }} {{ $t('PAYMENT_MGMT.SUBSCRIPTION.PER_YEAR') }} <br />
    {{ $tc('PAYMENT_MGMT.SUBSCRIPTION.USER_ACCOUNT', userCount, {count: userCount}) }} <woot-button class="ml-3 h-8" color-scheme="success" @click="handleUpgrade">{{ $t('PAYMENT_MGMT.SUBSCRIPTION.ADD_USER_COUNT_TXT') }}</woot-button> <br />
    {{ statusText }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
  },
  mixins: [timeMixin],
  props: {
    planName: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    unitAmount: {
      type: Number,
      default: 0
    },
    userCount: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: ''
    },
    endAt: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      getLocale: 'accounts/getLocale',
    }),
    statusText() {
      switch (this.status) {
        case 'active':
          return this.$t('PAYMENT_MGMT.SUBSCRIPTION.EXPIRE_AT', { endAt: this.expireDate(this.endAt, this.getLocale) });
        case 'unpaid':
          return this.$t('PAYMENT_MGMT.SUBSCRIPTION.UNPAID');
        case 'ended':
          return this.$t('PAYMENT_MGMT.SUBSCRIPTION.EXPIRED_AT', { endAt: this.expireDate(this.endAt, this.getLocale) });
        default:
          return '';
      }
    }
  },
  methods: {
    handleUpgrade() {
      this.$router.push({
        name: 'payment_upgrade',
        params: { accountId: this.$route.params.accountId },
      });
    },
  }
};
</script>
