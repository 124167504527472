import MessageApi from '../../../../api/inbox/message';
import ConversationAPI from '../../../../api/inbox/conversation';
import types from '../../../mutation-types';

export default {
  async translateMessage({ rootGetters }, { conversationId, messageId }) {
    try {
      const targetLanguage = rootGetters['accounts/getLocale'] || 'en';
      await MessageApi.translateMessage(
        conversationId,
        messageId,
        targetLanguage
      );
    } catch (error) {
      // ignore error
    }
  },

  setTranslateLang: async (
    { commit, state },
    { conversationId, translateLang }
  ) => {
    const { allConversations } = state;
    const selectedChat = allConversations.find(
      conversation => conversation.id === conversationId
    );
    if (!selectedChat) return;
    await ConversationAPI.updateTranslateLang(
      conversationId,
      translateLang,
    );
    selectedChat.translate_lang = translateLang;
    commit(types.UPDATE_CONVERSATION, selectedChat);
  },

};
